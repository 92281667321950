import React from 'react';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';
import { useTranslation } from 'react-i18next';

const Dialog = ({ message, onConfirm, onCancel, children }) => {

  const { t } = useTranslation('translation');
  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onCancel();
    }
  };

  useDisableOverflow(message !== '');

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white dark:bg-mainBackground rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 border-2 border-blue-500">
        <p className="text-lightMainText dark:text-headingText text-lg font-semibold text-center mb-4">{message}</p>
        <div className="flex justify-center space-x-4">
          <Button
            onClick={onCancel}
            variant='secondary'
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={onConfirm}
            variant='primary'
          >
            {t('confirm')}
          </Button>

        </div>
      </div>
    </div>
  );
};

export default Dialog;

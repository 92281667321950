import React from 'react';
import { t } from 'i18next';
import { COUNTRIES } from '../../../CountryList.ts';
import SelectCustom from '../../Input/SelectCustom.js';
import { TAXES_TYPES } from '../../../TaxesList.ts';

const FieldInputEvaluateOrder = ({ fields, invoiceFields, setInvoiceFields, inputClasses, labelClasses }) => {
  const countries = COUNTRIES.data;

  const getDataForFieldType = (type) => {
    switch (type) {
      case 'select-country':
        return countries;
      case 'select-tax':
        return TAXES_TYPES;
      default:
        return [];
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.name + index} className="mb-4 text-lightCardText dark:text-white">
          <label htmlFor={field.name} className={labelClasses}>
            {t(field.transl)}
          </label>
          {field.type === 'select-country' || field.type === 'select-tax' ? (
            <SelectCustom
              placeholder={t(field.placeholder)}
              index={field.name}
              data={getDataForFieldType(field.type)}
              label={field.label}
              name={field.name}
              inputClasses={inputClasses}
              value={invoiceFields[field.name] || ''}
              handleSetValue={(e) => setInvoiceFields({ ...invoiceFields, [field.name]: e.target.value })}
            />
          ) : (
            <input
              id={field.name}
              type={field.type}
              name={field.name}
              placeholder={t(field.placeholder)}
              required
              value={invoiceFields[field.name] || ''}
              onChange={(e) => setInvoiceFields({ ...invoiceFields, [field.name]: e.target.value })}
              className={inputClasses}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default FieldInputEvaluateOrder;


export const linkListaNozze = 'https://www.amazon.it/wedding/share/barbara-e-francesco';
export const linkSunsetPalmi = 'https://www.sunset-palmi.it';

export enum TagType {
  VARIE = 0,
  MARE = 1,
  CITTA = 2,
  MONTAGNA = 3,
  BORGHI = 4,
  TOUR_ESPERIENZE = 5
}

export const REGEX_TYPE = {
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_])[A-Za-z\d!@#$%^&*_]{10,}$/,
  PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  NAME: /^[a-zA-Z]{2,}(?: [a-zA-Z]+)*$/ // 2 characters minimum and no more than 1 white space between words

};

export const tag = [
  { value: TagType.VARIE, label: 'tags.varie' },
  { value: TagType.MARE, label: 'tags.mare' },
  { value: TagType.CITTA, label: 'tags.citta' },
  { value: TagType.MONTAGNA, label: 'tags.montagna' },
  { value: TagType.BORGHI, label: 'tags.borghi' },
  { value: TagType.TOUR_ESPERIENZE, label: 'tags.tour_esperienze' },
];




export enum TabType {
  ALL_USERS = 0,
  ONLY_PERSONAL_PICTURES = 1,
}
/* export const CURRENCIES = [
  { value: 0, label: 'AUD' },
  { value: 1, label: 'EUR' },
  { value: 2, label: 'USD' },
  { value: 3, label: 'GBP' },
]; */

export const LANGUAGES = [
  { id: 0, code: 'EN', label: 'English' },
  { id: 1, code: 'FR', label: 'Français' },
  { id: 2, code: 'IT', label: 'Italiano' },
  { id: 3, code: 'DE', label: 'Deutsch' },
  { id: 4, code: 'ES', label: 'Español' },
];

export const ROLES = [
  { id: 0, code: 'user', label: 'User' },
  { id: 1, code: 'admin', label: 'Admin' },
];

export const CURRENCIES = [
  { id: 0, code: 'AUD', label: 'AUD' },
  { id: 1, code: 'EUR', label: 'EUR' },
  { id: 2, code: 'USD', label: 'USD' },
  { id: 3, code: 'GBP', label: 'GBP' },
];
export const LICENSES_TYPE = [
  { id: 0, code: 'new', label: 'New' },
  { id: 1, code: 'extend', label: 'Extend' },
  { id: 2, code: 'renew', label: 'Renew' },
  { id: 4, code: 'update', label: 'Update' },
];

export const LOG_LEVEL = [
  { id: 1, code: 'INFO', label: 'Info', color: 'bg-gray-500' },
  { id: 2, code: 'WARNING', label: 'Warning', color: 'bg-yellow-500' },
  { id: 3, code: 'ERROR', label: 'Error', color: 'bg-red-500' },
  { id: 4, code: 'PAYMENT', label: 'Payment', color: 'bg-green-500' },
  { id: 4, code: 'SERVER', label: 'Server', color: 'bg-blue-300' },
];

export enum LOG_LEVEL_TYPE {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  PAYMENT = 'PAYMENT',
  SERVER = 'SERVER',
}

export const SIGN_UP_FIELDS = [
  { type: 'text', name: 'firstName', placeholder: 'Name', regex: REGEX_TYPE.NAME },
  { type: 'text', name: 'lastName', placeholder: 'Last Name', regex: REGEX_TYPE.NAME },
  { type: 'email', name: 'email', placeholder: 'Email', regex: REGEX_TYPE.EMAIL },
  { type: 'password', name: 'password', placeholder: 'Password', regex: REGEX_TYPE.PASSWORD },
  { type: 'select-language', name: 'language', placeholder: 'Select a language', label: 'Language', transl: "select_language" },
];
export const ADMIN_USER_FIELDS = [
  { type: 'text', name: 'firstName', placeholder: 'Name', regex: REGEX_TYPE.NAME },
  { type: 'text', name: 'lastName', placeholder: 'Last Name', regex: REGEX_TYPE.NAME },
  { type: 'email', name: 'email', placeholder: 'Email' },
  { type: 'select', name: 'role', placeholder: 'Role', transl: "choose_role" },
  { type: 'select-language', name: 'language', placeholder: 'Select a language', label: 'Language', transl: "select_language" },
];
export const EDIT_USER_FIELDS = [
  { type: 'text', name: 'firstName', placeholder: 'Name', regex: REGEX_TYPE.NAME },
  { type: 'text', name: 'lastName', placeholder: 'Last Name', regex: REGEX_TYPE.NAME },
  { type: 'email', name: 'email', placeholder: 'Email' },
  { type: 'select-language', name: 'language', placeholder: 'Select a language', label: 'Language', transl: "select_language" },
];
export const EDIT_ADD_ORDER_FIELDS = [
  { type: 'text', name: 'orderId', placeholder: 'Order ID' },
  { type: 'text', name: 'invoiceId', placeholder: 'Invoice ID' },
  { type: 'text', name: 'activationCode', placeholder: 'Activation Code' },
  { type: 'select', name: 'currency', placeholder: 'Currency' },
  { type: 'number', name: 'amount', placeholder: 'Amount' },
  { type: 'date', name: 'purchaseDate', placeholder: 'Purchase Date' },
  { type: 'date', name: 'licenseExpireDate', placeholder: 'License Expire Date' },
  { type: 'text', name: 'licenseId', placeholder: 'License ID' },
  { type: 'boolean', name: 'licenseValid', placeholder: 'Status' },
  { type: 'select-license-type', name: 'licenseType', placeholder: 'License Type' },
];

export const INVOICE_FIELDS = [
  { type: 'text', name: 'heading', label: 'Invoice Heading', placeholder: 'invoiceHeading_placeholder', transl: 'invoiceHeading' },
  { type: 'text', name: 'address', label: 'Address', placeholder: 'address_placeholder', transl: 'invoiceAddress' },
  { type: 'email', name: 'invoiceEmailAddress', label: 'Invoice Email Address', placeholder: 'E-mail', transl: 'email' },
  { type: 'text', name: 'phone', label: 'Phone Number', placeholder: 'phone_placeholder', transl: 'invoicePhone' },
  { type: 'text', name: 'taxIdentificationNumber', label: 'Tax Identification Number', placeholder: 'invoiceHeading_taxIdentificationNumber', transl: 'taxIdentificationNumber' },
  { type: 'select-country', name: 'country', placeholder: 'select_country', label: 'Country', transl: 'country' },
  { type: 'select-tax', name: 'taxType', placeholder: 'select_taxType', label: 'Tax Type', transl: 'taxType' },
];

export const LOGS_FIELDS = [
  { type: 'date', name: 'dateFrom', label: 'From', placeholder: 'From', transl: 'dateFrom' },
  { type: 'date', name: 'dateTo', label: 'To', placeholder: 'To', transl: 'dateTo' },
  { type: 'time', name: 'timeFrom', label: 'Time From', placeholder: 'From Time', transl: 'timeFrom' },
  { type: 'time', name: 'timeTo', label: 'Time To', placeholder: 'To Time', transl: 'timeTo' },
  { type: 'text', name: 'licenseId', placeholder: 'License ID', transl: 'licenseId' },
  { type: 'email', name: 'emailAddress', label: 'Email Address', placeholder: 'E-mail', transl: 'email' },
  { type: 'select-level', name: 'logLevel', placeholder: 'All', label: 'Log Level', transl: 'logLevel' },
];


// FieldInputSignUp.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FieldError from '../Input/FieldError';

const FieldInputSignUp = ({ field, formData, handleInputChange, showPassword, setShowPassword, fieldErrors, t }) => {
  return (
    <div className="mb-4 text-lightCardText dark:text-white  relative" key={field.name}>
      <label htmlFor={field.name} className="text-lightCardText dark:text-white  text-sm font-bold mb-2">
        {t(field.name)}
      </label>
      <input
        id={field.name}
        type={field.type === 'password' ? (showPassword ? 'text' : 'password') : field.type}
        placeholder={t(field.name)}
        required
        value={formData[field.name]}
        onChange={(e) => handleInputChange(e, field)}
        className={`border-blue-500 ${fieldErrors[field.name] ? 'border-red-500' : ''}`}
      />
      {field.type === 'password' && (
        <FontAwesomeIcon
          onClick={() => setShowPassword((prev) => !prev)}
          icon={showPassword ? faEyeSlash : faEye}
          className="absolute top-[40px] right-4 text-xl cursor-pointer"
        />
      )}
      {/* Use FieldError component */}
      <FieldError fieldName={field.name} fieldErrors={fieldErrors} />
    </div>
  );
};

export default FieldInputSignUp;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


const Footer = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {

    scrollToTop();
  }, [pathname]);

  return (
    <footer className="bg-lightSecondaryBackground dark:bg-secondaryBackground text-white pb-4 pt-6 md:pt-0 mt-2 md:mt-0">
      <div onClick={scrollToTop} className="hidden md:block w-full bg-lightSecondaryBackground dark:bg-gray-900 py-2 mb-8 text-center cursor-pointer hover:text-blue-600 transition duration-300 text-xl ">{t('back_to_top')}</div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* The Company */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6  text-center">
            <h3 className="text-xl text-lightPrimaryButton dark:gradient-text font-bold mb-4">{t('our_company')}</h3>
            <p className='text-white'>{t('our_company_text')}</p>
          </div>

          {/* Our Product */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6 text-center">
            <h3 className="text-xl text-lightPrimaryButton dark:gradient-text font-bold mb-4">{t('our_main_product')}</h3>
            <ul className="space-y-2">
              <li>       <p className='text-white'>{t('our_main_product_text')}</p></li>
            </ul>
          </div>
          {/* Contacts */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6  text-center">
            <h3 className="text-xl text-lightPrimaryButton dark:gradient-text font-bold mb-4">{t('contact')}</h3>
            <ul className="space-y-2">
              <li>   <p className='text-white'>support@aesofttech.com</p></li>
            </ul>
          </div>
          {/* Social Media */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6  text-center">
            <h3 className="text-xl text-lightPrimaryButton dark:gradient-text font-bold mb-4">{t('find_us_social_media')}</h3>
            <ul className="space-y-2 text-xl ">
              <li>  <a href="https://www.facebook.com/DaVinciDeluxe" target='_blank' className="hover:text-gray-400" rel="noreferrer">Facebook</a></li>
              <li>  <a href="https://www.youtube.com/watch?v=SXLeji3-mrU" target='_blank' className="hover:text-gray-400" rel="noreferrer">YouTube</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center border-t border-gray-700 pt-4">
          <p className="text-sm">© 2024 Francesco De Giorgio. Tutti i diritti riservati.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

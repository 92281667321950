import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { captureStripePayment, createStripeIntent } from '../../api/api';
import { useTranslation } from 'react-i18next';


const StripePaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');

  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  // Fetch the clientSecret on load
  useEffect(() => {
    handleCreateStripeIntent();
  }, [props.amount, props.currency]);

  const handleCloseDiaog = () => {

    props.setSuccess('');
    navigate(`/success?licenseId=${props?.licenseId}&userId=${props?.userId}&amount=${props.amount}&currency=${props.currency}&nosave=true&termsAndConditions=${props.invoiceFields?.termsAndConditions}&invoiceNeeded=${props.invoiceFields?.invoiceNeeded}&taxIdentificationNumber=${props.invoiceFields?.taxIdentificationNumber}&phone=${props.invoiceFields?.phone}&address=${props.invoiceFields?.address}&invoiceEmailAddress=${props.invoiceFields?.invoiceEmailAddress}&heading=${props.invoiceFields?.heading}&country=${props.invoiceFields?.country}&taxType=${props.invoiceFields?.taxType}`);
  }

  const handleCreateStripeIntent = async () => {

    try {
      const response = await createStripeIntent(props.amount, props.currency, props?.userId, props?.licenseId);

      if (response.clientSecret) {
        setClientSecret(response.clientSecret);
      }
    } catch (error) {
      console.error(error);
      props.setErrorMessage(`Error creating Stripe intent: ${error?.response?.data?.message} ${error?.response?.data?.error}`);
    }
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    props.setIsLoading(true);

    try {


      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        props.setErrorMessage(result.error.message);
        props.setIsLoading(false);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          /*         console.log('Payment successful'); */
          /*     console.log(result); */
          const paymentIntentId = result?.paymentIntent?.id;

          // Capture the payment and save the order details
          try {
            const response = await captureStripePayment(paymentIntentId, props?.userId, props?.licenseId, props.invoiceFields, props.type, props.selectedLicense);
            props.setSuccess(`Payment for order ${result?.paymentIntent?.id} successful! Your license ${response?.licenseId} is now valid`);

          } catch (err) {
            if (err?.response?.data?.message) {
              props.setErrorMessage(`${err?.response?.data?.message}`);
            } else {
              props.setErrorMessage(`Error capturing payment: ${err.message}`);
            }
            navigate(`/cancel`);
          } finally {
            props.setIsLoading(false);
          }



        }
      }
    } catch (error) {
      props.setErrorMessage(`Error processing payment: ${error.message}`);
      props.setIsLoading(false);
    }
  };

  const storedTheme = localStorage.getItem('theme');

  return (
    <div className="bg-white dark:bg-secondaryBackground flex flex-col  border-2 border-blue-500 shadow-md rounded-lg w-full max-w-3xl m-auto">
      <form onSubmit={handleSubmit} className='px-4'>
        <h1 className='text-lightCardText dark:text-white text-3xl font-bold my-6'>{t('stripe_card_details')}</h1>
        <CardElement
          options={{
            style: {
              base: {
                color: `${storedTheme === 'dark' ? '#fff' : '#000'}`,
                fontSize: '20px',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
            },
          }}
        />
        <div className='my-8 max-w-[400px] w-full m-auto'>

          <Button variant='primary' disabled={!stripe}>{t('stripe_pay')}</Button>
        </div>

        {props.success !== '' && <ConfirmationDialog message={props.success} closeDialog={handleCloseDiaog} />}
      </form >
    </div>
  );
};

export default StripePaymentForm;
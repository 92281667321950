import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import Button from './Button';
import NewOrderModal from './NewOrderModal';
import { getDowloadLink } from '../api/external-api';
import ManageLicensesButtons from './Orders/ManageLicensesButtons';
import { FaDownload } from 'react-icons/fa';
const Home = () => {
  const { t } = useTranslation('translation');
  const { user, setErrorMessage, setIsLoading, isUserLoggedIn } = useContext(AuthContext);


  const [newLicenseModal, setNewLicenseModal] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [licenseType, setLicenseType] = useState('');
  const navigate = useNavigate();

  const handleOpenModal = (type) => {
    if (!isUserLoggedIn) {
      navigate('/login'); // Redirect if the user is not logged in
    } else {
      setLicenseType(type); // Set the license type directly
      setNewLicenseModal(true); // Open the modal for both 'new' and 'extend'
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      handleGetDownloadLink();
    }
  }, [isUserLoggedIn]);

  const handleGetDownloadLink = async () => {

    try {
      if (isUserLoggedIn) {
        const response = await getDowloadLink();
        setDownloadLink(response);
      }
    } catch (error) {
      setErrorMessage('Error getting download link');
      setDownloadLink('')
    } finally {
      setIsLoading(false);
    }

  }
  return (
    <div className="relative mt-4">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground ">
        <div className="flex-grow flex items-center justify-start  flex-col ">
          <img src={logo} className='max-w-[400px]' alt='logo' />
          <h1 className="text-2xl md:text-3xl text-center font-semibold  text-secondaryText  dark:text-headingText  my-8 relative">
            DaVinci Deluxe &#8211; Automotive Calibration Software and Engineering
          </h1>


          {isUserLoggedIn && <div className='w-full mb-4' id="downloadSoftwareId">
            <a href={downloadLink}>
              <Button type="button" variant="primary"  >

                <span className='flex justify-center items-center' >  <FaDownload className="mr-2" /> Download DaVinci Deluxe   </span> </Button></a>
          </div>}
          <ManageLicensesButtons handleClick={handleOpenModal} />
        </div>
      </section>

      {newLicenseModal && <NewOrderModal userId={user?._id} isOpen={newLicenseModal} licenseType={licenseType} setErrorMessage={setErrorMessage} onClose={() => setNewLicenseModal(false)} setIsLoading={setIsLoading} />}

    </div>
  );
}

export default Home;
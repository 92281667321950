import React, { useState, useContext, useCallback, useMemo } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import logo from '../assets/logo.png';
import InputPassword from './Input/InputPassword';
import Loader from './UI/Loader/Loader';
import ResetPasswordModal from './UsersManagement/Password/ResetPasswordModal';
import { useTranslation } from 'react-i18next';
import SignUpModal from './SignUp/SignUp';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, setErrorMessage, setIsLoading, isLoading, isUserLoggedIn, user } = useContext(AuthContext);
  const [signUp, setSignUp] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(email, password);
      window.location.href = '/';
    } catch (error) {
      const errorMessage = error?.response?.data?.message === 'Invalid credentials'
        ? t('invalid_credentials')
        : `${t('failed_to_login')}: ${error?.response?.data?.message ?? error?.response?.data}`;
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [email, password, login, setErrorMessage, setIsLoading, t]);

  const handleViewOrders = useCallback(() => {
    navigate(`/orders`, { state: { userId: user?._id } });
  }, [navigate, user?._id]);

  const renderLoginForm = useMemo(() => (
    <>
      <form onSubmit={handleSubmit} className='w-full mb-2 text-white'>
        <label htmlFor="email" className='text-secondaryText dark:text-white text-sm font-bold mb-2'>
          {t('email')}
        </label>
        <input
          type="text"
          placeholder={t('email')}
          id="email"
          value={email}
          className='border-blue-500'
          onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          required
        />
        <InputPassword
          top='[40px]'
          handleSetValue={(e) => setPassword(e.target.value)}
          value={password}
          inputClasses={'border-blue-500'}
        />
        <div className='mt-4'>
          <Button type="submit" variant="primary"> Login </Button>
        </div>
      </form>
      <Button type="button" variant="secondary" onClick={() => setSignUp(true)}>
        {t('signup')}
      </Button>
    </>
  ), [email, password, handleSubmit, setSignUp, t]);

  return (
    <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white  dark:bg-mainBackground ">
      <div className="flex-grow flex items-center justify-start flex-col">
        <img src={logo} className='max-w-[400px]' alt='logo' />
        {!isUserLoggedIn ? (
          <>
            {renderLoginForm}
            <h1
              onClick={() => setResetPassModal(true)}
              className='w-full text-right text-lightMainText dark:text-headingText mt-4 underline text-lg cursor-pointer hover:text-blue-500 dark:hover:text-white'
            >
              {t('forgot_password')}
            </h1>
          </>
        ) : (
          <>
            <h1 className='text-white my-4'>{t('logged_in')}</h1>
            <Button onClick={handleViewOrders} variant='primary'>
              {t('view_orders')}
            </Button>
          </>
        )}
        <Loader isLoading={isLoading} />
      </div>

      {signUp && (
        <SignUpModal
          isOpen={signUp}
          setErrorMessage={setErrorMessage}
          onClose={() => setSignUp(false)}
          setIsLoading={setIsLoading}
          login={login}
        />
      )}

      {resetPassModal && (
        <ResetPasswordModal
          userId={user?._id}
          isOpen={resetPassModal}
          setErrorMessage={setErrorMessage}
          onClose={() => setResetPassModal(false)}
          setIsLoading={setIsLoading}
        />
      )}
    </section>
  );
};

export default Login;

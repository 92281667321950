import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';
import { validateField, validateForm } from '../../utils/validation';
import PasswordInput from './FieldInput/PasswordInput';
import FieldInput from './FieldInput/FieldInput';
import { useTranslation } from 'react-i18next';

// Make sure to set the app root element for accessibility
Modal.setAppElement('#root');

const UserFormModal = ({ isOpen, onClose, formData, setFormData, editUser, createUser, updateUser, editFields, loggedUser }) => {
  const [fieldErrors, setFieldErrors] = useState({});

  const { t } = useTranslation();
  // Handle input changes and validation
  const handleInputChange = (e, field) => {
    const { value } = e.target;

    // Update form data
    setFormData((prevData) => ({ ...prevData, [field.name]: value }));

    // Validate field
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field.name]: validateField(field, value)
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(editFields, formData);
    setFieldErrors(errors);

    if (!Object.values(errors).some(Boolean)) {
      editUser === 'add' ? createUser() : updateUser();
    }
  };

  useDisableOverflow(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="User Form Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground overflow-hidden rounded-lg text-left outline-none w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto"
    >
      <div className="p-6 relative">
        <h1 className=" text-lightMainText dark:gradient-text  text-3xl font-bold">
          {editUser === 'add' ? t('create_user') : t('update_user')} {editUser === 'update' && formData.email}
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <span className="absolute top-0 right-0 m-4 text-gray-500 hover:text-white focus:outline-none cursor-pointer" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </span>

          {editFields.map((field) => (
            <FieldInput
              key={field.name}
              field={field}
              value={formData[field.name]}
              handleInputChange={handleInputChange}
              fieldErrors={fieldErrors}
              isDisabled={loggedUser.role !== 'admin' && field.name === 'email'}
            />
          ))}

          {editUser === 'add' && (
            <PasswordInput
              value={formData.password}
              setFormData={setFormData}
              setFieldErrors={setFieldErrors}
              fieldErrors={fieldErrors}
            />
          )}

          <div className="flex justify-end">
            <Button variant="primary" type="submit">
              {editUser === 'add' ? t('create_user') : t('update_user')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserFormModal;

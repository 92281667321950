

import { useEffect } from "react";

const useDisableOverflow = (isOpen) => {
  // Use effect to manage the body's overflow style when the modal is open
  useEffect(() => {

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    // Cleanup function to reset overflow when the modal is closed
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

}

export default useDisableOverflow;
























import React from 'react';
import SelectCustom from '../../Input/SelectCustom.js';
const RenderingInputFields = ({ label, type, value, onChange, placeholder, className, options, isSelect }) => {
  return (
    <div className="flex flex-col mb-2 w-full sm:w-auto min-w-[130px]">
      <label className=" text-secondaryText dark:text-white text-sm font-bold mb-0 md:mb-2 mt-0">{label}</label>
      {isSelect ? (
        <div>
          <SelectCustom
            index={value}
            data={options}
            required={false}
            label={label}
            inputClasses={`${className} h-[50px]`}
            name={value}
            value={value}
            handleSetValue={(e) => onChange(e.target.value)}
            placeholder={placeholder}
          />
        </div>
      ) : (
        <input
          type={type}
          className={`${className} h-[50px]`}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
};
export default RenderingInputFields;

import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AuthContext } from '../../context/AuthContext';
import CheckoutButton from './CheckoutButton';
import StripePaymentForm from './StripePaymentForm';

const STRIPE_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV : process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const STRIPE_PRICE_NEW = process.env.REACT_APP_API_STRIPE_PRICE_NEW;
const STRIPE_PRICE_EXTEND = process.env.REACT_APP_API_STRIPE_PRICE_EXTEND;
const STRIPE_CURRENCY = process.env.REACT_APP_API_STRIPE_CURRENCY;
const stripePromise = loadStripe(STRIPE_KEY);

const StripePaymentPage = () => {

  const { setErrorMessage, setIsLoading } = useContext(AuthContext);
  const [success, setSuccess] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const { invoiceFields, userId, licenseId, type, selectedLicense } = location.state || {}; // Destructure licenseId from the state

  return (
    <div className='w-full m-auto flex items-center justify-center'>

      <div className='max-w-[800px] w-full'>

        <h1 className="text-lightMainText dark:text-headingText text-3xl font-bold text-center">
          {type === 'new' ? t('buy_new_license') : type === 'renew' ? t('renew_your_license') : t('extend_your_license')}
        </h1>

        <div className='my-8'>


          <Elements stripe={stripePromise} className='w-full'>

            <CheckoutButton
              licenseId={licenseId}
              selectedLicense={selectedLicense}
              invoiceFields={invoiceFields}
              type={type}
              setIsLoading={setIsLoading}
              setSuccess={setSuccess}
              success={success}
              setErrorMessage={setErrorMessage}
              userId={userId}
              amount={type === 'new' ? STRIPE_PRICE_NEW : STRIPE_PRICE_EXTEND}
              currency={STRIPE_CURRENCY}
            /*   amount={invoiceFields.amount} */
            /*             currency={invoiceFields.currency} */

            />
            <div className='my-8' />

            <StripePaymentForm
              licenseId={licenseId}
              selectedLicense={selectedLicense}
              type={type}
              invoiceFields={invoiceFields}
              setIsLoading={setIsLoading}
              setSuccess={setSuccess}
              success={success}
              setErrorMessage={setErrorMessage}
              userId={userId}
              amount={type === 'new' ? STRIPE_PRICE_NEW : STRIPE_PRICE_EXTEND}
              currency={STRIPE_CURRENCY}
              /*   amount={invoiceFields.amount} */
/*               currency={invoiceFields.currency}  */ />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default StripePaymentPage;

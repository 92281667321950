import React from 'react';


const FaqItem = ({ text, classes }) => {


  return (
    <div className={`faq-item p-4 rounded-md flex justify-between  border-0 dark:border-2 border-blue-500 flex-col items-start mb-6 md:mb-10 bg-lightCardBackground dark:bg-secondaryBackground`} >
      <h2 className={classes.titleClasses}>{text.question}</h2>
      <p className={classes.paragraphClasses}>{text.answer}</p>
    </div>
  );
}

export default FaqItem;
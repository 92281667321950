// FieldError.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const FieldError = ({ fieldName, fieldErrors }) => {
  const { t } = useTranslation();

  if (!fieldErrors[fieldName]) {
    return null;
  }

  return (
    <span className="text-red-500 text-sm flex mt-1">
      {t(`${fieldName}_invalid`)}
    </span>
  );
};

export default FieldError;
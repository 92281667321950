import React from 'react';
import { FaEdit, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const UserList = ({ users, handleUpdateUser, handleDeleteUser, loggedUser, handleOpenModal }) => {

  const navigate = useNavigate(); // Hook for navigation

  const handleUserOrders = (userId) => {
    navigate(`/orders`, { state: { userId } });
  };

  const { t } = useTranslation('translation');
  return (

    <ul className="flex flex-col gap-4 items-center w-full m-auto mt-0 md:mt-8">
      {users?.map((user, index) => (
        <li key={user?._id + index} className="flex flex-col border-0 dark:border-2 border-blue-500 bg-lightCardBackground dark:bg-secondaryBackground shadow-md rounded-lg p-4 w-full max-w-3xl">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold  text-lightMainText dark:text-headingText">{user?.firstName} {user?.lastName}</h2>
            <div className="flex space-x-2">
              <span className="text-blue-500 hover:text-blue-700 cursor-pointer" onClick={() => handleUpdateUser(user)}>
                <FaEdit size={20} />
              </span>
              {loggedUser.role === 'admin' && <span className="text-red-500 hover:text-red-700 cursor-pointer" onClick={() => handleDeleteUser(user)}>
                <FaTrash size={20} />
              </span>}
            </div>
          </div>
          <div className="mt-2 text-lightCardText dark:text-white">
            <p className="mb-1 text-lightCardText dark:text-white"><span className="font-bold">ID:</span> {user?._id}</p>
            <p className="mb-1 text-lightCardText dark:text-white"><span className="font-bold">Email:</span> {user?.email}</p>
            {loggedUser.role === 'admin' && <p className="mb-1 text-lightCardText dark:text-white"><span className="font-bold">{t('role')}:</span> {user?.role}</p>}
            <p className="mb-1 text-lightCardText dark:text-white"><span className="font-bold">{t('language')}:</span> {user?.language}</p>
            <p className="mb-1 text-lightCardText dark:text-white"><span className="font-bold">{t('nr_of_licenses')}:</span> {user?.licenses?.length}</p>

            <div className='mt-6'>
              {loggedUser.role === 'admin' &&
                <Button
                  variant="primary"
                  onClick={() => handleUserOrders(user?._id)}
                >
                  <span className='flex justify-center items-center' >   <FaPencilAlt className="mr-2" /> {t('edit_user_orders')}</span>
                </Button>}
              <h1
                onClick={() => handleOpenModal('changePassword', user)}
                className='text-right text-lightMainText dark:text-headingText mt-4 underline text-lg cursor-pointer hover:text-lightCardText  e'
              >      {t('change_password')}
              </h1>
            </div>

          </div>
        </li>
      ))}
    </ul>

  );
};

export default UserList;

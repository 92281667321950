import React, { useState, useContext, useEffect } from 'react';
import UserList from './UsersList';
import Button from '../Button';
import UserFormModal from './UserFormModal';
import { AuthContext } from '../../context/AuthContext';
import Dialog from '../UI/Dialog';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { FaPlus } from 'react-icons/fa';
import useFethcUsers from '../../hooks/useFetchUsers';
import { createUsers, deleteSelectedUser, fetchUsers, updateSelectedUser, updateUserPasswordAdmin } from '../../api/api';
import Pagination from '../Pagination';
import Searchbar from './Searchbar';
import ChangePasswordModal from './ChangePasswordModal';
import { ADMIN_USER_FIELDS } from '../../Constants.ts';
import { useTranslation } from 'react-i18next';

/* const apiUrl = process.env.REACT_APP_API_URL; */

const UserManagement = () => {
  const { t } = useTranslation('translation');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // For debouncing

  const [formData, setFormData] = useState({ email: '', firstName: '', lastName: '', password: '', role: 'user', language: 'en' });
  const [editUser, setEditUser] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const [dialogMessage, setDialogMessage] = useState('');


  const [modalState, setModalState] = useState({ isOpen: false, type: '' }); // Manage both modals

  const { setErrorMessage, setIsLoading, user } = useContext(AuthContext);



  const ITEMS_PER_PAGE = process.env.REACT_APP_API_USERS_PER_PAGE || 12;

  const { users, totalPages, currentPage, setCurrentPage, setUsers, refreshUsers } = useFethcUsers(fetchUsers, ITEMS_PER_PAGE, setIsLoading, debouncedSearchTerm, setErrorMessage);

  useEffect(() => {
    // Debouncing the searchTerm to avoid multiple API calls
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);


  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 210)
    }
  };


  const handleCloseDialog = () => {
    setDialogMessage('');
  }

  const createUser = async () => {
    setIsLoading(true);
    try {
      const response = await createUsers(formData);
      setUsers([...users, response.data]);
      setFormData({ email: '', password: '', role: 'user', language: 'en' });
      setModalState({ isOpen: false, type: '' });
      setIsLoading(false);
      setDialogMessage(t('user_created'));
      refreshUsers();
    } catch (error) {
      setIsLoading(false);
      console.error(t('error_create'), error);
      setErrorMessage(`${t('error_create')}: ${error?.response?.data?.message}`);
    }
  };

  const updateUser = async (isPasswordUpdate = false) => {
    setIsLoading(true);
    try {
      if (isPasswordUpdate) {
        await updateUserPasswordAdmin(selectedUser?._id, formData.password);
        setModalState({ isOpen: false, type: '' });
      } else {
        const response = await updateSelectedUser(selectedUser._id, formData);
        const updatedUsers = users.map(user => (user._id === selectedUser._id ? response : user));
        setUsers(updatedUsers);
      }
      setSelectedUser(null);
      setModalState({ isOpen: false, type: '' });
      setIsLoading(false);
      setDialogMessage(t('user_updated'));
      setFormData({ email: '', password: '', role: 'user', language: 'en' });
      refreshUsers();
    } catch (error) {
      setIsLoading(false);
      console.error(t('error_update'), error);
      setErrorMessage(t('error_update'));

    }
  };

  const handleUpdateUser = (user) => {
    setEditUser('update');
    setSelectedUser(user);
    setFormData({ email: user.email, firstName: user.firstName, lastName: user.lastName, role: user.role, language: user.language });
    setModalState({ isOpen: true, type: 'updateUser' });
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setModalState({ isOpen: true, type: 'showConfirmation' });
  };

  const confirmDeleteUser = async () => {
    setIsLoading(true);
    if (selectedUser) {
      try {
        await deleteSelectedUser(selectedUser._id, formData);
        const updatedUsers = users.filter((user) => user._id !== selectedUser._id);
        setUsers(updatedUsers);
        setSelectedUser(null);
        setModalState({ isOpen: false, type: '' });
        setIsLoading(false);
        setCurrentPage(1);
        refreshUsers();
      } catch (error) {
        setIsLoading(false);
        setModalState({ isOpen: false, type: '' });
        setErrorMessage('Error deleting user: ' + error?.response?.data?.message);
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleOpenModal = (type, user) => {
    setSelectedUser(user);
    setFormData({ ...formData, _id: user?._id });
    setModalState({ isOpen: true, type });
  };



  const cancelDeleteUser = () => {
    setSelectedUser(null);
    setModalState({ isOpen: false, type: '' });
  };

  return (
    <>
      <div className="w-full mb-4 max-w-3xl m-auto mt-0 md:mt-8">
        <Button type="button" variant={'primary'} onClick={() => { setModalState({ isOpen: true, type: 'updateUser' }); setEditUser(editUser === 'add' ? '' : 'add') }}>
          <span className='flex justify-center items-center' >   <FaPlus className="mr-2" /> {t('add_user')}</span>
        </Button>
      </div>
      {currentPage === 1 && <Searchbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}
      {modalState.isOpen && modalState.type === 'updateUser' && (<UserFormModal
        loggedUser={user}
        isOpen={modalState.isOpen && modalState.type === 'updateUser'}
        onClose={() => {
          setModalState({ isOpen: false, type: '' }); // Close the modal
          setEditUser('');
          setFormData({ email: '', password: '', firstName: '', lastName: '', role: 'user', language: 'en' });
        }}
        editFields={ADMIN_USER_FIELDS}
        formData={formData}
        setFormData={setFormData}
        editUser={editUser}
        createUser={createUser}
        updateUser={updateUser}
      />)}
      <UserList users={users} handleUpdateUser={handleUpdateUser} handleDeleteUser={handleDeleteUser} loggedUser={user} handleOpenModal={handleOpenModal} />

      {users?.length > 0 && <Pagination handlePageChangeClicked={handlePageChange} totalPages={totalPages} currentPage={currentPage}></Pagination>}

      {modalState.isOpen && modalState.type === 'showConfirmation' && (
        <Dialog
          message={`${t('delete_user')} ${selectedUser.firstName} ${selectedUser.lastName}?`}
          onConfirm={confirmDeleteUser}
          onCancel={cancelDeleteUser}
        />
      )}
      {dialogMessage !== '' && <ConfirmationDialog message={dialogMessage} closeDialog={handleCloseDialog} />}

      {modalState.isOpen && modalState.type === 'changePassword' && (
        <ChangePasswordModal
          isOpen={modalState.isOpen}
          onClose={() => setModalState({ isOpen: false, type: '' })}
          formData={formData}
          setFormData={setFormData}
          handleUpdatePassword={() => updateUser(true)}
        />
      )}
    </>
  );
};

export default UserManagement;

import React, { useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '../Button';
import { AuthContext } from '../../context/AuthContext';
import { createStripeCheckoutSession } from '../../api/api';

const CheckoutButton = (props) => {
  const stripe = useStripe();

  const { setErrorMessage } = useContext(AuthContext);

  const handleClick = async (event) => {
    try {


      const items = [{
        name: props.licenseId,
        amount: props.amount,
        quantity: 1,
        currency: props.currency,
        userId: props.userId,
        invoiceEmailAddress: props.invoiceFields.invoiceEmailAddress,
        invoiceNeeded: props.invoiceFields.invoiceNeeded,
        heading: props.invoiceFields.heading,
        address: props.invoiceFields.address,
        phone: props.invoiceFields.phone,
        taxIdentificationNumber: props.invoiceFields.taxIdentificationNumber,
        licenseValid: props.invoiceFields.licenseValid,
        licenseExpireDate: props.invoiceFields.licenseExpireDate,
        purchaseDate: props.invoiceFields.purchaseDate,
        termsAndConditions: props.invoiceFields.termsAndConditions,
        country: props.invoiceFields.country,
        taxType: props.invoiceFields.taxType,
        selectedLicense: props.selectedLicense
      }];
      const response = await createStripeCheckoutSession(items, props.type, props.selectedLicense);

      const { id: sessionId } = await response;

      const result = await stripe.redirectToCheckout({ sessionId });
      console.log(result);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Unauthorized: Please log in to continue.');
      }
      console.error(error);

    }

  };

  return (
    <div className="bg-white dark:bg-secondaryBackground flex flex-col  border-2 border-blue-500 shadow-md rounded-lg w-full max-w-3xl m-auto px-4 ">
      <h1 className='text-lightCardText dark:text-white text-3xl font-bold my-6'>Express checkout </h1>
      <div className='mb-8 max-w-[400px] w-full m-auto'>

        <Button onClick={handleClick} variant="primary">
          Express Checkout
        </Button>
      </div>
    </div>
  );
};

export default CheckoutButton;

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import Button from '../Button';
import { savePaymentCheckout } from '../../api/api';
import { useTranslation } from 'react-i18next';
import Loader from '../UI/Loader/Loader';


const SuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setErrorMessage } = useContext(AuthContext);
  const { country, taxType, invoiceEmailAddress, invoiceNeeded, heading, phone, address, taxIdentificationNumber, termsAndConditions, sessionId, licenseId, userId, amount, currency, nosave, licenseExpireDate, purchaseDate, licenseValid, type, selectedLicense } = Object.fromEntries(queryParams.entries());
  const parsedExpireDate = new Date(licenseExpireDate);
  const parsedPurchaseDate = new Date(purchaseDate);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('translation');

  const navigate = useNavigate();
  let selectedLicenseDecoded;
  if (selectedLicense !== null && selectedLicense !== undefined && selectedLicense !== 'undefined') {
    selectedLicenseDecoded = JSON.parse(decodeURIComponent(selectedLicense));
  }

  useEffect(() => {
    setIsLoading(true);
    if (nosave !== 'true') {
      handleSavePaymentFromCheckout();
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const handleSavePaymentFromCheckout = async () => {
    setIsLoading(true);
    try {
      const paymentData = {
        userId,
        licenseId,
        amount,
        currency,
        invoiceNeeded,
        invoiceEmailAddress,
        heading,
        phone,
        address,
        taxIdentificationNumber,
        country,
        taxType,
        termsAndConditions,
        sessionId,
        licenseExpireDate: parsedExpireDate,
        purchaseDate: parsedPurchaseDate,
        licenseValid: licenseValid === 'true',
        type,
        selectedLicense: selectedLicenseDecoded,
      };

      await savePaymentCheckout(paymentData);

    } catch (err) {
      if (err?.response?.data?.message) {
        setErrorMessage(`${err?.response?.data?.message}`);
      } else {
        setErrorMessage(`Error capturing payment: ${err.message}`);
      }
    }
  };

  const handleViewOrders = () => {
    navigate(`/orders`, { state: { userId, licenseId, amount, currency } });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen-footer bg-white dark:bg-mainBackground ">
      <div className="bg-white dark:bg-mainBackground  rounded-lg shadow-lg text-center max-w-4xl w-full p-4">
        <div className="flex items-center justify-center mb-6">
          <div className="bg-green-500 text-lightCardText dark:text-white p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
        <h1 className="text-2xl font-bold text-lightMainText dark:text-headingText mb-4">ORDER COMPLETED!</h1>
        <p className="text-lightCardText dark:text-white mb-2 text-xl">Paid {currency} {amount}  License Id: {licenseId}</p>
        {/*         {sessionId && <p className="text-lightCardText dark:text-white mb-2 text-sm">Session Id: {sessionId}  </p>} */}
        <p className='text-lightCardText dark:text-white text-sm mb-6'>Thank you for your purchase!</p>
        <p className='text-lightCardText dark:text-white text-sm mb-6'>{termsAndConditions}</p>
        <Button
          onClick={handleViewOrders}
          variant='primary'
        >
          {t('view_orders')}
        </Button>
      </div>
      {isLoading !== '' && <Loader isLoading={isLoading} />}
    </div>

  );
};

export default SuccessPage;

// components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const NotFound = () => {

  const { t } = useTranslation('translation');

  return (
    <div className="relative mt-4">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground ">
        <div className="flex-grow flex items-center justify-start  flex-col w-full ">
          <img src={logo} className='max-w-[400px]' alt='logo' />
          <h1 className="text-2xl md:text-3xl text-center font-semibold  text-lightMainText dark:text-headingText my-8 relative mt-16">
            404 - {t('page_not_found')}
          </h1>
          <p className="mb-4 text-white text-xl">{t('page_not_found_text')}</p>
          <Link to="/" className="text-blue-500 w-full mt-4">
            <Button type="button" variant="primary"  >
              {t('go_back_home')}
            </Button>
          </Link>
        </div>
      </section>


    </div>
  );
};

export default NotFound;



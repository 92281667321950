import React, { useContext, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';
import { capturePayPalOrder, createPayPalOrder } from '../../api/api';
import ConfirmationDialog from '../UI/ConfirmationDialog';


const PayPalPaymentPage = () => {

  const { setErrorMessage, errorMessage, setIsLoading, user } = useContext(AuthContext);
  const [success, setSuccess] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const { invoiceFields, userId, licenseId, type, selectedLicense } = location.state || {}; // Destructure licenseId from the state

  const navigate = useNavigate(); // Hook for navigation

  const PAYPAL_CLIENT_ID = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PAYPAL_CLIENT_ID_DEV : process.env.REACT_APP_PAYPAL_CLIENT_ID;

  const createOrderHandler = async (data, actions) => {
    try {
      const response = await createPayPalOrder(invoiceFields.amount, invoiceFields.currency, user, licenseId);
      return response.id; // Order ID
    } catch (err) {
      setErrorMessage('Error creating order.');
      navigate(`/cancel`);
    }
  };

  const handleApprove = async (data) => {
    setIsLoading(true);
    try {
      if (!userId) {
        setErrorMessage('User not found');
        throw new Error('User not found');
      }

      if (!data.orderID) {
        setErrorMessage('Order not found');
        throw new Error('Order not found');
      }
      const response = await capturePayPalOrder(data.orderID, userId, licenseId, invoiceFields, type, selectedLicense);

      setSuccess(`Payment for order ${response?.orderId} successful! Your license ${response?.licenseId} is now valid.`);


    } catch (err) {
      if (err?.response?.data?.message) {
        setErrorMessage(`${err?.response?.data?.message}`);
      } else {
        const errorResponse = err?.request?.response; // Get the raw response
        const parsedResponse = JSON.parse(errorResponse); // Parse the JSON
        const errorDetails = JSON.parse(parsedResponse?.error ? parsedResponse?.error : parsedResponse?.response?.message) || {}; // Parse the `error` field which is a stringified JSON
        console.log('Error Message:', parsedResponse?.message); // Log the main error message
        console.log('Error Details:', errorDetails);
      }
      navigate(`/cancel`);
    } finally {
      setIsLoading(false);
    }
  };


  const handleCloseDiaog = () => {
    setSuccess('');
    navigate(`/success?licenseId=${licenseId}&userId=${userId}&amount=${invoiceFields.amount}&currency=${invoiceFields.currency}&nosave=true`);
  }

  const cancelHandler = () => {
    navigate(`/cancel`);
  };
  const errorHandler = (err) => {
    console.log(err);
    setErrorMessage(`Error capturing payment: ${err.message} ${err.error}`);
    setIsLoading(false)
  }

  return (
    <div className='w-full m-auto flex items-center justify-center'>

      <div className='max-w-[400px] w-full'>

        <h1 className="text-lightMainText dark:text-headingText text-3xl font-bold mb-4">
          {type === 'new' ? t('buy_new_license') : type === 'renew' ? t('renew_your_license') : t('extend_your_license')}
        </h1>
        <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID, currency: "EUR" }}>
          <PayPalButtons
            createOrder={createOrderHandler}
            onApprove={handleApprove}
            onCancel={cancelHandler}
            onError={errorHandler}
          />
          {errorMessage && <p>{errorMessage}</p>}
          {success !== '' && <ConfirmationDialog message={success} closeDialog={handleCloseDiaog} />}
        </PayPalScriptProvider>

      </div>
    </div>
  );

};

export default PayPalPaymentPage;




import React from 'react';
import { useTranslation } from 'react-i18next';
import FaqItem from './FaqItem';
import video_it from '../../assets/faq_video_it.mp4';
import video_en from '../../assets/faq_video_en.mp4';
import aesoftech from '../../assets/aesoftech.png';

const FAQ = () => {

  const { t } = useTranslation('faq');
  const userPrefLanguage = localStorage.getItem('language') ?? 'en';
  const titleClasses = 'font-bold text-lightCardText dark:text-headingText underline';
  const paragraphClasses = 'text-lightCardText dark:text-white text-xl leading-10 my-6';

  return (
    <div className="relative">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground">
        <div className="flex-grow flex items-center justify-start flex-col">
          <h1 className="text-2xl text-center text-lightCardText dark:text-white mt-0 md:mt-8 mb-6 font-bold">
            FAQ - {t('faq_heading')}
          </h1>

          {/* Video Section */}
          {userPrefLanguage === 'it' && <div className="mb-8">
            <video controls className="w-full h-auto">
              <source src={video_it} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          }
          {userPrefLanguage === 'en' && <div className="mb-8">
            <video controls className="w-full h-auto">
              <source src={video_en} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          }


          <div className="faq-content text-lightCardText dark:text-white">
            {[...Array(10)].map((_, i) => (
              <FaqItem key={i} classes={{ titleClasses, paragraphClasses }} text={{ question: t(`faq_${i + 1}_title`), answer: t(`faq_${i + 1}_content`) }} />
            ))}
          </div>

          <img src={aesoftech} alt='aesoftech davinci deluxe' />
        </div>
      </section>
    </div>
  );
}

export default FAQ;

// external-api.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_WEBAPI_VALIDATE_ORDER;
const downloadLinkUrl = process.env.REACT_APP_WEBAPI_DOWNLOAD_LINK;

// Create an axios instance
const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json', // Set Content-Type header
  }
});

export const validateOrder = async (password) => {
  try {
    const response = await api.post('/api/Admin/ValidateOrder', { password });
    return response.data[0];
  } catch (error) {
    console.error('Error validating order:', error.response ? error.response.data : error.message);
    throw error;
  }
};
export const getDowloadLink = async () => {
  try {
    const response = await api.get(downloadLinkUrl);

    return response.data;

  } catch (error) {
    console.error('Error getting download link:', error.response ? error.response.data : error.message);
    throw error;
  }
};






import { useContext, useEffect, useRef, useState } from "react";
import { getAllLogs } from "../../api/api";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { LOG_LEVEL } from "../../Constants.ts";
import { format, parseISO, subHours } from 'date-fns';
import Button from "../Button.js";
import { LOGS_FIELDS } from "../../Constants.ts";
import RenderingInputFields from "./RenderingInputFields/RenderingInputFields.js";
import { toZonedTime } from 'date-fns-tz';
import FilterButtons from "./FilterButtons.js";
import DeleteLogsDialog from "./DeleteLogsDialog.js";

const LogsPage = () => {
  const [logs, setLogs] = useState([]);

  const [liveMode, setLiveMode] = useState(false);
  const intervalRef = useRef(null); // Store the interval
  const [deleteLogsModalOpen, setDeleteLogsModalOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    dateFrom: new Date().toISOString().split('T')[0], // Default today
    dateTo: new Date().toISOString().split('T')[0], // Default today
    timeFrom: "",
    timeTo: "",
    emailAddress: "",
    logLevel: "",
    licenseId: "",

  });

  const { t } = useTranslation();
  const { setErrorMessage, setIsLoading } = useContext(AuthContext);
  const formatData = (date) => {
    const timeZone = 'Europe/Rome';  // Specify the Rome timezone
    const zonedDate = toZonedTime(parseISO(date), timeZone);  // Convert the date to the Rome timezone
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss');  // Format the date
  };

  const handleReset = () => {
    setFormValues({
      dateFrom: new Date().toISOString().split('T')[0], // Default today
      dateTo: new Date().toISOString().split('T')[0], // Default today
      timeFrom: "",
      timeTo: "",
      emailAddress: "",
      logLevel: "",
      licenseId: "",
    });
  };

  const handleLiveMode = () => {
    fetchLogs();
    setLiveMode((prevValue) => !prevValue);

    if (!liveMode) {
      // Start live mode
      intervalRef.current = setInterval(() => {
        fetchLogs();
      }, 15000);
    } else {
      // Stop live mode
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    // Clean up the interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const fetchLogs = async () => {
    setIsLoading(true);
    // Subtract 2 hours from timeFrom and timeTo
    const adjustedTimeFrom = formValues.timeFrom
      ? format(subHours(new Date(`${formValues.dateFrom}T${formValues.timeFrom}:00`), 2), 'HH:mm')
      : null;

    const adjustedTimeTo = formValues.timeTo
      ? format(subHours(new Date(`${formValues.dateTo}T${formValues.timeTo}:00`), 2), 'HH:mm')
      : null;
    try {
      const data = await getAllLogs(
        formValues.dateFrom,
        formValues.dateTo,
        formValues.emailAddress,
        adjustedTimeFrom,
        adjustedTimeTo,
        formValues.logLevel,
        formValues.licenseId
      );
      setLogs(data.logs);
    } catch (error) {
      console.error(t("error_fetching_logs"), error);
      setErrorMessage(`${t("error_fetching_logs")}: ${error?.response?.data?.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    fetchLogs();
  };

  const handleDeleteLogs = async () => {
    setIsLoading(true);
    setDeleteLogsModalOpen(true);
  };
  return (
    <form onSubmit={handleSubmitSearch} className="mb-4 text-white">
      <div className="mb-4 text-white">
        <div className="flex flex-col sm:flex-row gap-2  mb-4 flex-wrap">

          {LOGS_FIELDS.map((field) => (
            <RenderingInputFields
              key={field.name}
              label={t(field.transl || field.label)}
              type={field.type === 'select-level' ? 'select' : field.type}
              value={formValues[field.name]}
              onChange={(value) => handleInputChange(field.name, value)}
              placeholder={t(field.placeholder)}
              className='border-blue-500'
              options={field.type === 'select-level' ? LOG_LEVEL : null}
              isSelect={field.type === 'select-level'}
            />
          ))}
          <div className="flex flex-col sm:flex-row m-0 flex-wrap gap-2">
            <FilterButtons liveMode={liveMode} onHandleLiveMode={handleLiveMode} /* onHandleFetchLogs={fetchLogs} */ onHandleReset={handleReset} />
          </div>
        </div>


        <Button type="submit" classes={''} variant="primary">
          {t("FILTER")}
        </Button>
      </div>

      <div>
        {logs.map((log, index) => (
          <div key={index}>
            <h3>{log.email}</h3>
            <ul>
              {log.logs.map((entry, idx) => {
                const logLevel = LOG_LEVEL.find(level => level?.code === entry?.level);

                return (
                  <li key={idx}>
                    <div className={`p-2 border-b-2 border-cyan-200 text-lg ${logLevel?.color || 'bg-gray-500'}`}>
                      <span className="font-bold text-black uppercase">
                        {logLevel?.label || entry?.level}
                      </span>
                      <span> {formatData(entry?.time)} </span>

                      {entry?.message && (
                        <span> - {entry?.message} </span>
                      )}

                      {entry?.email?.trim() && (
                        <span> - {entry?.email} </span>
                      )}

                      {entry?.licenseId.trim() && (
                        <span> - {entry?.licenseId} </span>
                      )}
                    </div>
                  </li>

                );
              })}
            </ul>
          </div>
        ))}
      </div>
      <Button variant="danger" classes={'my-4'} onClick={handleDeleteLogs}> Delete Logs</Button>
      {deleteLogsModalOpen && <DeleteLogsDialog onCancel={() => setDeleteLogsModalOpen(false)} setErrorMessage={setErrorMessage} setIsLoading={setIsLoading} />}
    </form>
  );
};

export default LogsPage;

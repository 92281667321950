import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import Button from '../Button';
import { INVOICE_FIELDS } from '../../Constants.ts';
import { validateOrder } from '../../api/external-api.js';
import { format, parseISO } from 'date-fns';
import { enUS, it } from 'date-fns/locale';
import { t } from 'i18next';
import FieldInputEvaluateOrder from './FieldInput/FieldInputEvaluateOrder.js';

const EvaluateOrder = () => {
  const location = useLocation();
  const { userId, licenseId, type } = location.state || {};
  const navigate = useNavigate();
  const { setIsLoading, setErrorMessage, isLoading, user } = useContext(AuthContext);

  const [invoiceFields, setInvoiceFields] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [invoiceNeeded, setInvoiceNeeded] = useState(false);
  const [currentLicense, setCurrentLicense] = useState({});
  const inputClasses = 'border-blue-500';
  const labelClasses = 'text-lightCardText dark:text-white text-sm font-bold mb-2';
  const locale = user?.language?.toLowerCase() === 'it' ? it : enUS;

  const formatData = (date) => {
    return format(parseISO(date), 'dd MMMM yyyy', {
      locale: locale
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (!licenseId) {
      handleInvalidLicenseId();
    }
    setInvoiceFields({ ...invoiceFields, invoiceNeeded: false });
    if (type === 'new') {
      evaluateNewOrder();
    } else {
      evaluateOrder();
    }
  }, [licenseId, setIsLoading, setErrorMessage]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleInvoiceNeededChange = (e) => {
    setInvoiceNeeded(e.target.checked);
    setInvoiceFields({ ...invoiceFields, invoiceNeeded: e.target.checked });
  };

  const evaluateOrder = async () => {
    scrollToTop();
    setIsLoading(true);
    try {
      const response = await validateOrder(licenseId.trim());
      setCurrentLicense({ ...response, userId, licenseExpireDate: response.licenseExpireDate });
      if (!response || !response.amount || !response.currency || !response.licenseExpireDate || !response.purchaseDate || !response.currency) {
        setErrorMessage('Error evaluating order');
        handleInvalidLicenseId();
      }

      setInvoiceFields({
        ...invoiceFields,
        termsAndConditions: response.termsAndConditions,
        amount: response.amount,
        licenseExpireDate: response.licenseExpireDate,
        purchaseDate: response.purchaseDate,
        licenseValid: response.licenseValid,
        currency: response.currency,
      });
    } catch (error) {
      setErrorMessage('Error evaluating order');
      handleInvalidLicenseId();
    } finally {
      setIsLoading(false);
    }
  };

  const evaluateNewOrder = async () => {
    scrollToTop();
    setIsLoading(true);
    try {
      const nowDate = new Date();
      const licenseExpireDate = new Date(nowDate).setFullYear(nowDate.getFullYear() + 1); // Add 12 months
      setInvoiceFields((prevFields) => ({
        ...prevFields,
        amount: process.env.REACT_APP_FIRST_PURCHASE || 1400,
        termsAndConditions: 'DaVinci Deluxe license is valid for 1 year. The price quoted already includes any applicable fees.',
        licenseExpireDate: new Date(licenseExpireDate).toISOString(),
        purchaseDate: nowDate.toISOString(),
        licenseValid: true,
        currency: 'eur',
      }));
    } catch (error) {
      setErrorMessage('Error creating new order');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInvalidLicenseId = () => {
    setIsLoading(false);
    setErrorMessage('License not found or invalid, please try again.');
    navigate('/');
    return;
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    if (!licenseId || !paymentMethod || !invoiceFields || !userId) {
      setErrorMessage('Missing required fields');
      return;
    }

    if (paymentMethod === 'paypal') {
      navigate('/paypal-payment', {
        state: { invoiceFields, userId, licenseId, type, selectedLicense: { ...currentLicense, ...invoiceFields } },
      });
    } else if (paymentMethod === 'stripe') {
      navigate('/stripe-payment', {
        state: { invoiceFields, userId, licenseId, type, selectedLicense: currentLicense },
      });
    } else {
      setErrorMessage('Please select a payment method');
    }
  };

  return (
    <div className="relative mt-4">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto dark:bg-mainBackground">
        <h1 className="text-2xl md:text-3xl text-center font-semibold text-lightMainText dark:text-headingText mb-8 relative">
          {t('register_new_license')}
        </h1>

        {!isLoading && (
          <div>
            <p className="text-lightCardText dark:text-white text-xl">
              HD Id: <span className="font-bold"> {licenseId}</span>
            </p>
            <p className="text-lightCardText dark:text-white text-xl">
              {t('license_type')}: <span className="uppercase font-bold"> {t(type)}</span>
            </p>
            <p className="text-lightCardText dark:text-white text-xl">
              {t('amount')}: <span className="uppercase font-bold"> {invoiceFields.amount} {invoiceFields?.currency}</span>
            </p>
            {invoiceFields?.licenseExpireDate && (
              <p className="text-lightCardText dark:text-white text-xl">
                {t('expire_date')}: <span className="uppercase font-bold"> {formatData(invoiceFields?.licenseExpireDate)}</span>
              </p>
            )}

            <form onSubmit={handlePaymentSubmit} className="space-y-4">
              <div className="mb-4 flex gap-2 items-center text-lightCardText dark:text-white text-xl my-8">
                <label
                  className={'text-lightCardText dark:text-white text-xl m-0 uppercase cursor-pointer font-bold'}
                  htmlFor="invoiceNeeded"
                >
                  {t('need_invoice')}
                </label>
                <input
                  id="invoiceNeeded"
                  type="checkbox"
                  checked={invoiceNeeded}
                  onChange={handleInvoiceNeededChange}
                  className={
                    'm-0 cursor-pointer w-6 h-6 text-blue-600 bg-gray-100 border-blue-500 rounded focus:border-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  }
                />
              </div>

              {invoiceNeeded && (
                <FieldInputEvaluateOrder
                  fields={INVOICE_FIELDS}
                  invoiceFields={invoiceFields}
                  setInvoiceFields={setInvoiceFields}
                  inputClasses={inputClasses}
                  labelClasses={labelClasses}
                />
              )}

              <div className="w-full mt-4">
                <Button
                  type="button"
                  classes="text-lightCardText dark:text-white bg-blue-500 border-blue-500"
                  variant="paypal"
                  onClick={() => handlePaymentMethodSelect('paypal')}
                >
                  PayPal
                </Button>
              </div>
              <div className="w-full mt-4">
                <Button
                  type="button"
                  variant="stripe"
                  onClick={() => handlePaymentMethodSelect('stripe')}
                >
                  Stripe
                </Button>
              </div>
            </form>

            {invoiceFields.termsAndConditions && (
              <p className="text-lightCardText dark:text-white text-xl">
                {t('terms_and_conditions')}: <span className="font-bold">{invoiceFields.termsAndConditions}</span>
              </p>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default EvaluateOrder;

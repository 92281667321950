import { validateTokenUtil } from '../api/api';

const validateToken = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return { valid: false };
  }

  try {
    const response = await validateTokenUtil();
    return response;
  } catch (error) {
    console.error('Token validation error:', error.response ? error?.response?.data : error?.message);
    throw error;
  }
};

export default validateToken;

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const InputPassword = ({ label = 'Password', id = 'password', value, handleSetValue, inputClasses, helperText, top = '[40px]' }) => {
  const [showPassword, setShowPassword] = useState(false);

  const labelClasses = ' text-secondaryText dark:text-white text-sm font-bold mb-2';

  return (
    <div className="mb-4  text-secondaryText dark:text-white relative">
      <label htmlFor={id} className={labelClasses}>
        {label}
      </label>
      <input
        id={id}
        required
        type={showPassword ? 'text' : 'password'}
        placeholder={label}
        value={value}
        onChange={handleSetValue}
        className={inputClasses}
      />
      {helperText && <p className="text-red-500 text-sm flex mt-1">{helperText}</p>}
      <FontAwesomeIcon
        onClick={() => setShowPassword((prev) => !prev)} // Use functional update for state
        icon={showPassword ? faEyeSlash : faEye}
        className={`absolute top-${top} right-4 text-xl cursor-pointer`}
        title={showPassword ? 'Hide password' : 'Show password'} // Accessibility improvement
      />
    </div>
  );
};

export default InputPassword;

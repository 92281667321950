import React, { createContext, useState, useEffect } from 'react';
import validateToken from './Validate';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../components/UI/ErrorDialog';
import Loader from '../components/UI/Loader/Loader';
import { userLogin } from '../api/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const { i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const [loading, setLoading] = useState(true); //serve per il componente privateRouter, vedere di unificare
  const [isLoading, setIsLoading] = useState(false); // serve per il loader
  const [errorMessage, setErrorMessage] = useState('');

  const login = async (email, password) => {
    const response = await userLogin(email, password);
    setUser(response);
    localStorage.setItem('token', response.token);
    setIsUserLoggedIn(true);
    setLoading(false);
    setUserRole(response.role);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('language');
    localStorage.removeItem('user');
    setIsUserLoggedIn(false);
    setLoading(false);

  };

  const closeDialog = async () => {
    setErrorMessage('');
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      setIsLoading(true);
      const userString = localStorage.getItem('user');
      const user = userString ? JSON.parse(userString) : null;

      let userPrefLanguage = localStorage.getItem('language') || user?.language?.toLowerCase() || 'en';

      if (user) {
        setUser(user);
        setIsUserLoggedIn(true);
        setLoading(false);
        setUserRole(user.role);
        userPrefLanguage = localStorage.getItem('language') || user?.language?.toLowerCase() || 'en';
        i18n.changeLanguage(userPrefLanguage);

        if (!localStorage.getItem('language')) {
          localStorage.setItem('language', userPrefLanguage);
        }
      }

      try {
        const result = await validateToken();
        if (result.valid) {

          setUser(result.user);
          setIsUserLoggedIn(true);
          setLoading(false);
          setUserRole(result.user.role);
          localStorage.setItem('user', JSON.stringify(result.user));
          userPrefLanguage = localStorage.getItem('language') || result.user?.language?.toLowerCase() || 'en';
          i18n.changeLanguage(userPrefLanguage);

          if (!localStorage.getItem('language')) {
            localStorage.setItem('language', userPrefLanguage);
          }
        } else {
          //  logout();
          setIsLoading(false);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        //setErrorMessage(`${error?.response?.data.message}, please try again.`);
        // logout();
        if (error.status === 401 || error.status === 404) {
          setErrorMessage(`${error?.response?.data.message}`);
          logout();
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    checkTokenValidity();
  }, [user?.language]);


  return (
    <AuthContext.Provider value={{ user, setUser, loading, login, logout, isUserLoggedIn, userRole, errorMessage, setErrorMessage, isLoading, setIsLoading }}>
      {children}
      {errorMessage !== '' && <ErrorDialog errorMessage={errorMessage} closeDialog={closeDialog} />}
      {isLoading !== '' && <Loader isLoading={isLoading} />}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
/* import { useTranslation } from 'react-i18next'; */
import Loader from './UI/Loader/Loader';

const PrivateRoute = ({ children, roles }) => {

  /*   const { t } = useTranslation(); */
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <Loader isLoading={loading} />; // You can replace this with a proper loading component or spinner
  }


  if (!user || (roles && !roles.includes(user.role))) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;

import React from 'react';
import SelectCustom from '../../Input/SelectCustom';
import FieldError from '../../Input/FieldError';
import { LANGUAGES, ROLES } from '../../../Constants.ts';
import { useTranslation } from 'react-i18next';

const FieldInput = ({ field, value, handleInputChange, fieldErrors, isDisabled }) => {
  const isSelect = field.type === 'select' || field.type === 'select-language';

  const { t } = useTranslation();

  return (
    <div className="mb-4 text-lightCardText dark:text-white" key={field.name}>
      <label htmlFor={field.name} className="text-lightCardText dark:text-white text-sm font-bold mb-2">
        {t(field.name)}
      </label>
      {isSelect ? (
        <SelectCustom
          id={field.name}
          data={field.type === 'select' ? ROLES : LANGUAGES}
          name={field.name}
          value={value}
          inputClasses="border-blue-500"
          handleSetValue={(e) => handleInputChange(e, field)}
          placeholder={t(field.transl)}
        />
      ) : (
        <input
          id={field.name}
          type={field.type}
          placeholder={t(field.name)}
          required
          value={value}
          disabled={isDisabled}
          onChange={(e) => handleInputChange(e, field)}
          className={`border-blue-500 ${fieldErrors[field.name] ? 'border-red-500' : ''}`}
        />
      )}
      <FieldError fieldName={field.name} fieldErrors={fieldErrors} />
    </div>
  );
};

export default FieldInput;

import React from 'react';


const PriceItem = ({ text, classes }) => {

  return (
    <div className={`faq-item p-4 rounded-md  justify-between  border-0 dark:border-2 border-blue-500 flex flex-col items-start mb-3 md:mb-6 bg-lightCardBackground dark:bg-secondaryBackground`} >
      <h2 className={classes.titleClasses}>{text.title}</h2>
      <div className='flex flex-row flex-wrap gap-2 items-center justify-center'>

        <p className={classes.contentClasses}>{text.content} € </p>
        <span className={classes.feeClasses}>{text.feeParagraph} €</span>
      </div>
    </div>
  );
}

export default PriceItem;
import React, { useContext, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../Button';
import { resetPassword } from '../../../api/api';
import { AuthContext } from '../../../context/AuthContext';
import logo from '../../../assets/logo.png';
import { t } from 'i18next';
import { validateField } from '../../../utils/validation';
import FieldError from '../../Input/FieldError';
import { REGEX_TYPE } from '../../../Constants.ts';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({ message: '', error: '' });
  const { token } = useParams();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AuthContext);
  const [fieldErrors, setFieldErrors] = useState({ password: false });

  const handleChange = (e) => {
    console.log('pino')
    const { value } = e.target;
    setPassword(value); // Update password state as a string
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      password: validateField({ regex: REGEX_TYPE.PASSWORD }, value), // Pass field name and value to validation function
    }));
  };

  const handlePasswordReset = async () => {
    setIsLoading(true);
    try {
      await resetPassword(token, password);
      navigate('/login');
    } catch (err) {
      console.error(err);
      setStatus({ message: '', error: 'Error: ' + err?.response?.data?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!fieldErrors.password) {
        handlePasswordReset();
      }
    },
    [token, password, fieldErrors] // Make sure to pass fieldErrors as a dependency
  );

  return (
    <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground">
      <div className="flex-grow flex items-center justify-start flex-col gap-4">
        <img src={logo} className="max-w-[400px]" alt="logo" />
        <h1 className="text-2xl md:text-3xl text-center font-semibold text-lightMainText dark:text-headingText my-8 relative">
          Password Reset
        </h1>
        <form onSubmit={handleSubmit} className="text-white text-xl w-full">
          <label htmlFor="password" className="text-white text-sm font-bold mb-2">
            {t('enter_new_password')}
          </label>
          <input
            type="password"
            value={password}
            onChange={handleChange}
            placeholder={t('password')}
            className={`border-blue-500 ${fieldErrors.password ? 'border-red-500' : ''}`}
            required
          />
          <Button type="submit" variant="primary">
            {t('enter_new_password')}
          </Button>
          {status.error && <p className="text-red-500 text-lg">{status.error}</p>}
          {status.message && <p className="text-green-500 text-lg">{status.message}</p>}

          <FieldError fieldName="password" fieldErrors={fieldErrors} />
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;

import React, { useState } from 'react';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';
import { useTranslation } from 'react-i18next';
import { deleteLogs } from '../../api/api';

const DeleteLogsDialog = ({ message, onCancel, setErrorMessage, setIsLoading }) => {

  const { t } = useTranslation('translation');

  const [days, setDays] = useState(30);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onCancel();
    }
  };

  useDisableOverflow(message !== '');

  const handleConfirmDeleteLogs = async () => {
    setIsLoading(true);
    try {
      await deleteLogs(days);
    } catch (error) {
      console.error('Unable to delete logs', error);
      setErrorMessage(`Unable to delete logs: ${error?.response?.data?.message}`);
    } finally {
      setIsLoading(false);
    }
    onCancel();
  };


  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white dark:bg-mainBackground rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 border-2 border-blue-500">
        <p className="text-lightMainText dark:text-headingText text-lg font-semibold text-center mb-4">How many days of logs would you like to delete?</p>

        <input
          type="number"
          placeholder={'How many days you want to delete?'}
          id="days"
          value={days}
          className='border-blue-500 my-4'
          onChange={(e) => setDays(e.target.value)}
          required
        />

        <div className="flex justify-center space-x-4">
          <Button
            onClick={onCancel}
            variant='secondary'
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleConfirmDeleteLogs}
            variant='primary'
          >
            {t('confirm')}
          </Button>

        </div>

      </div>
    </div>
  );
};

export default DeleteLogsDialog;

import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {

  const { t } = useTranslation('contact');

  return (
    <div className="relative">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground">
        <div className="flex-grow flex items-center justify-start flex-col">
          <h1 className="text-2xl text-center  mt-0 md:mt-8 mb-6 font-bold text-lightMainText dark:text-white">
            {t('contact_title')}
          </h1>

          <div className="w-full faq-item p-4 rounded-md flex justify-between  border-0 dark:border-2 border-blue-500  flex-col items-start mb-6 md:mb-10 bg-lightCardBackground dark:bg-secondaryBackground">
            <h2 className="text-2xl font-semibold text-lightMainText dark:text-headingText">Email</h2>
            <p className="text-lightCardText dark:text-white my-4 text-lg ">
              <a href="mailto:support@aesoftech.com" className="text-primary hover:underline">
                E: support@aesoftech.com        </a>

            </p>
            <p className="text-lightCardText dark:text-white my-4 text-lg ">
              <a href="https://aesoftech.com/" target="_blank" className="text-primary hover:underline" rel="noreferrer"> W: https://aesoftech.com/
              </a>
            </p>

            <h2 className="text-2xl font-semibold text-lightMainText dark:text-headingText">{t('business_hours')}</h2>
            <p className="text-lightCardText dark:text-white my-4 text-lg">
              {t('business_hours_label')}
            </p>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Contact;

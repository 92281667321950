export const TAXES_TYPES = [
  {
    "id": 1,
    "code": "IVA",
    "country": "Italy",
    "label": "IVA"
  },
  {
    "id": 2,
    "code": "GST",
    "country": "Australia",
    "label": "Goods and Services Tax"
  },
  {
    "id": 3,
    "code": "VAT",
    "country": "United Kingdom",
    "label": "Value Added Tax"
  },
  {
    "id": 4,
    "code": "TVA",
    "country": "France",
    "label": "Taxe sur la Valeur Ajoutée"
  },
  {
    "id": 5,
    "code": "MST",
    "country": "Canada",
    "label": "Manufacturer's Sales Tax"
  },
  {
    "id": 6,
    "code": "IVA",
    "country": "Spain",
    "label": "Impuesto sobre el Valor Añadido"
  },
  {
    "id": 7,
    "code": "ICMS",
    "country": "Brazil",
    "label": "Imposto sobre Circulação de Mercadorias e Serviços"
  },
  {
    "id": 8,
    "code": "PIT",
    "country": "United States",
    "label": "Personal Income Tax"
  },
  {
    "id": 9,
    "code": "CST",
    "country": "India",
    "label": "Central Sales Tax"
  },
  {
    "id": 10,
    "code": "VAT",
    "country": "Germany",
    "label": "Mehrwertsteuer"
  },
  {
    "id": 11,
    "code": "MWST",
    "country": "Switzerland",
    "label": "Mehrwertsteuer"
  },
  {
    "id": 12,
    "code": "RTS",
    "country": "Russia",
    "label": "Regional Tax on Sales"
  },
  {
    "id": 13,
    "code": "JCT",
    "country": "Japan",
    "label": "Japanese Consumption Tax"
  },
  {
    "id": 14,
    "code": "GST",
    "country": "New Zealand",
    "label": "Goods and Services Tax"
  },
  {
    "id": 15,
    "code": "BPT",
    "country": "Singapore",
    "label": "Business Profits Tax"
  },
  {
    "id": 16,
    "code": "IVA",
    "country": "Mexico",
    "label": "Impuesto al Valor Agregado"
  },
  {
    "id": 17,
    "code": "CIT",
    "country": "China",
    "label": "Corporate Income Tax"
  },
  {
    "id": 18,
    "code": "TVA",
    "country": "Belgium",
    "label": "Taxe sur la Valeur Ajoutée"
  },
  {
    "id": 19,
    "code": "TDS",
    "country": "India",
    "label": "Tax Deducted at Source"
  },
  {
    "id": 20,
    "code": "VAT",
    "country": "South Africa",
    "label": "Value Added Tax"
  },
  {
    "id": 21,
    "code": "IVA",
    "country": "Argentina",
    "label": "Impuesto al Valor Agregado"
  },
  {
    "id": 22,
    "code": "SCT",
    "country": "Turkey",
    "label": "Special Consumption Tax"
  },
  {
    "id": 23,
    "code": "GST",
    "country": "Malaysia",
    "label": "Goods and Services Tax"
  },
  {
    "id": 24,
    "code": "PIT",
    "country": "Poland",
    "label": "Podatek dochodowy od osób fizycznych"
  },
  {
    "id": 25,
    "code": "IT",
    "country": "Indonesia",
    "label": "Income Tax"
  }
]

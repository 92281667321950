import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useFetchUsers = (fetchFn, ITEMS_PER_PAGE, setIsLoading, searchTerm = '', setErrorMessage) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false); // New state to trigger refresh

  const { t } = useTranslation('translation');
  const refreshUsers = () => setRefresh(prev => !prev); // Function to trigger refresh

  useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      try {
        const data = await fetchFn(currentPage, ITEMS_PER_PAGE, searchTerm);
        setUsers(data.users);
        setTotalPages(data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error(t('error_fetching_users'), error);
        setErrorMessage(`${t('error_fetching_users')}: ${error?.response?.data?.message}`);
        setIsLoading(false);
      }
    };
    getUsers();
  }, [fetchFn, currentPage, searchTerm, refresh]); // Only re-fetch when refresh changes

  return {
    users,
    currentPage,
    totalPages,
    setCurrentPage,
    setUsers,
    refreshUsers, // Return this function to trigger refresh from outside
  };
};

export default useFetchUsers;

import React, { useState, useContext, useEffect, useCallback } from 'react';
import UserList from './UsersList';
import UserFormModal from './UserFormModal';
import { AuthContext } from '../../context/AuthContext';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import ChangePasswordModal from './ChangePasswordModal';
import { getUserById, updateSelectedUser, updateUserPassword } from '../../api/api';
import { EDIT_USER_FIELDS } from '../../Constants.ts';
import { useTranslation } from 'react-i18next';

const UserInfo = () => {
  const [modalState, setModalState] = useState({ isOpen: false, type: '' }); // Manage both modals
  const [formData, setFormData] = useState({ email: '', firstName: '', lastName: '', password: '', role: 'user', language: 'en' });
  const [dialogMessage, setDialogMessage] = useState('');
  const { setErrorMessage, setIsLoading, user: loggedUser } = useContext(AuthContext);
  const [user, setUser] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    getUser();

  }, [loggedUser]);

  const getUser = async () => {
    setIsLoading(true);
    try {
      const response = await getUserById(loggedUser?._id);
      if (response) {
        setUser(response);
        setFormData(response);
      }
    } catch (error) {
      console.error(t('error_fetching_user_data'), error);
      setErrorMessage(t('error_fetching_user_data'));
    } finally {
      setIsLoading(false);
    }
  }
  const handleCloseDialog = useCallback(() => setDialogMessage(''), []);

  const updateUserData = async (isPasswordUpdate = false) => {
    setIsLoading(true);
    try {
      if (isPasswordUpdate) {
        await updateUserPassword(user?._id, formData.password);
      } else {
        await updateSelectedUser(user?._id, formData);
      }
      setModalState({ isOpen: false, type: '' });
      setDialogMessage(`${isPasswordUpdate ? 'Password' : t('user')} ${t('updated_succesfully')}`);
      setUser(formData);
    } catch (error) {
      setErrorMessage('Error updating user: ' + error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = (type) => {
    setModalState({ isOpen: true, type });
  };

  return (
    <>


      {user && <UserList users={[user]} handleUpdateUser={() => handleOpenModal('updateUser')} loggedUser={user} handleOpenModal={handleOpenModal} />}

      {dialogMessage && (
        <ConfirmationDialog message={dialogMessage} closeDialog={handleCloseDialog} />
      )}

      {modalState.isOpen && modalState.type === 'updateUser' && (
        <UserFormModal
          isOpen={modalState.isOpen}
          onClose={() => setModalState({ isOpen: false, type: '' })}
          formData={formData}
          setFormData={setFormData}
          editFields={EDIT_USER_FIELDS}
          editUser={'update'}
          updateUser={updateUserData}
          loggedUser={loggedUser}
        />
      )}

      {modalState.isOpen && modalState.type === 'changePassword' && (
        <ChangePasswordModal
          isOpen={modalState.isOpen}
          onClose={() => setModalState({ isOpen: false, type: '' })}
          formData={formData}
          setFormData={setFormData}
          handleUpdatePassword={() => updateUserData(true)}
        />
      )}

    </>
  );
};

export default UserInfo;

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FaCopy, FaPencilAlt, FaTrash } from 'react-icons/fa';
import Button from '../Button';
import { format, parseISO } from 'date-fns';
import { requestActivationCode } from '../../api/api';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

const LicensesList = ({ licenses, user, handleDeleteLicense, handleOpenEditLicenseModal, /* handleRenewOrExtend, */ loggedUserId, handleFetchLicenses, setIsLoading, setErrorMessage }) => {

  const [success, setSuccess] = useState('');
  const [isRequestingActivationCodeAvailable, setIsRequestingActivationCodeAvailable] = useState(false);
  const { t } = useTranslation('translation');
  const formatData = (date) => {
    return format(parseISO(date), 'MMMM dd, yyyy');
  }

  useEffect(() => {
    setTimeout(() => {
      setIsRequestingActivationCodeAvailable(true)
    }, 2500)
  })
  const handleRequestActivationCode = async (license) => {
    if (!isRequestingActivationCodeAvailable) {
      handleFetchLicenses();
      return;
    }
    try {
      setIsLoading(true);


      const response = await requestActivationCode(license.orderId, loggedUserId, license.licenseId);

      setSuccess(response.message);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setSuccess('Code copied to clipboard');
  }

  const handleCloseDialog = () => {
    setSuccess('');
    handleFetchLicenses();
  }
  return (<ul className="flex flex-col gap-4 items-center w-full m-auto">
    {licenses?.map((license, index) => (
      <li key={index} className="bg-lightCardBackground dark:bg-secondaryBackground flex flex-col  border-0 dark:border-2 border-blue-500 shadow-md rounded-lg p-4 w-full max-w-3xl">
        <div className="flex flex-col gap-2 mb-4">
          <div className="flex flex-row justify-between ">
            <p className="text-xl font-semibold text-lightMainText dark:text-headingText">HD ID:</p>
            <p className='text-xl text-lightMainText dark:text-headingText flex items-center gap-2 justify-end'> {license.licenseId} <span className="text-red-500 hover:text-red-700 cursor-pointer" onClick={() => handleCopyCode(license?.licenseId)}>
              <FaCopy size={20} className='text-lightCardText dark:text-white' />
            </span>
              {user.role === 'admin' && <span className="text-red-500 hover:text-red-700 cursor-pointer" onClick={() => handleDeleteLicense(license)}>
                <FaTrash size={20} />
              </span>}
            </p>
          </div>
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white ">{t('order')}:</p>
            <p className='text-lightCardText dark:text-white'>{license.orderId}</p>
          </div>
          {license.activationCode ? (
            <div className="flex flex-row justify-between ">
              <p className="font-semibold text-lightCardText dark:text-white ">{t('activation_code')}:</p>

              <p className='text-lightCardText dark:text-white flex flex-row gap-2 w-full justify-end items-center'>{license.activationCode}<span className="text-red-500 hover:text-red-700 cursor-pointer" onClick={() => handleCopyCode(license?.activationCode)}>
                <FaCopy size={20} className='text-lightCardText dark:text-white' />
              </span></p>

            </div>
          ) : (
            <div className="flex flex-row justify-between">
              <Button variant='primary' onClick={() => handleRequestActivationCode(license)}>{t('request_activation_code')}</Button>

            </div>
          )}
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white ">{t('invoice')}:</p>
            <p className='text-lightCardText dark:text-white'>{license?.invoiceId}</p>
          </div>

          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('purchase_date')}:</p>
            <p className='text-lightCardText dark:text-white'>{formatData(license?.purchaseDate)}</p>
          </div>
          {license?.licenseType && <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('license_type')}:</p>
            <p className='text-lightCardText dark:text-white uppercase'>{license?.licenseType}</p>
          </div>}
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('expire_date')}:</p>
            <p className='text-lightCardText dark:text-white'>{formatData(license?.licenseExpireDate)}</p>
          </div>
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('amount')}:</p>
            <p className='text-lightCardText dark:text-white'>{license.amount}</p>
          </div>
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('currency')}:</p>
            <p className='text-lightCardText dark:text-white'>{license.currency}</p>
          </div>
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">{t('terms_and_conditions')}:</p>
            <p className='text-lightCardText dark:text-white text-right'>{license.termsAndConditions}</p>
          </div>
          <div className="flex flex-row justify-between ">
            <p className="font-semibold text-lightCardText dark:text-white">Status:</p>
            <p className='text-lightCardText dark:text-white'>
              {license.licenseValid ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-red-500" />
              )}
            </p>
          </div>
        </div>
        {/*    <div className="flex justify-end mb-2">
          <Button
            onClick={() => handleRenewOrExtend(license, license.licenseValid ? 'extend' : 'renew')}
            variant='primary'
          >
            <span className='flex justify-center items-center' >   <FaInfinity className="mr-2" />  {license.licenseValid ? t('extend_license') : t('renew_license')} </span>
          </Button>
        </div> */}
        {user.role === 'admin' && <div className="flex justify-end">
          <Button
            onClick={() => handleOpenEditLicenseModal(license, 'edit')}
            variant='secondary'
          >
            <span className='flex justify-center items-center' >   <FaPencilAlt className="mr-2" /> {t('edit_order')}</span>
          </Button>
        </div>
        }
      </li>
    ))}
    {success !== '' && <ConfirmationDialog message={success} closeDialog={handleCloseDialog} />}

  </ul >);

}

export default LicensesList;
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';
import { deleteSelectedLicenseFromUser, fetchLicensesByUserId } from '../../api/api';
import NewOrderModal from '../NewOrderModal';
import EditOrderModal from './EditOrder';
import Dialog from '../UI/Dialog';
import { FaPlus } from 'react-icons/fa';
import LicensesList from './LicensesList';
import Button from '../Button';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import ManageLicensesButtons from './ManageLicensesButtons';


const Orders = () => {
  const location = useLocation();
  const { user, setErrorMessage, setIsLoading } = useContext(AuthContext);
  const [licenses, setLicenses] = useState([]);
  const { t } = useTranslation('translation');
  const [success, setSuccess] = useState('');

  const [licenseType, setLicenseType] = useState('');
  const [selectedLicenseToBeDeleted, setSelectedLicenseToBeDeleted] = useState(null);

  const [newOrderModal, setNewOrderModalOpen] = useState(false);
  const [editLicenseModalOpen, setEditLicenseModalOpen] = useState(false);
  const [addLicenseModalOpen, setAddLicenseModalOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);

  useEffect(() => {
    handleFetchLicenses();
  }, [user]);

  const userId = location.state?.userId || user?._id;


  const handleFetchLicenses = async () => {
    setIsLoading(true);
    if (!userId) {
      setErrorMessage('User not found');
      setIsLoading(false);
      return;
    }
    try {
      const response = await fetchLicensesByUserId(1, 22, userId)
      setLicenses(response.data.licenses);
    } catch (error) {
      console.error(t('error_fetching_licenses'), error);
      setErrorMessage(t('error_fetchin_licenses'));
    } finally {
      setIsLoading(false);
    }

  }
  /* qui ho i due handler per aprire e chiudere la modale di edit licenza */
  const handleCloseEditLicenseModal = (type) => {
    if (type === 'add') {
      setAddLicenseModalOpen(false);
    } else {

      setEditLicenseModalOpen(false);
    }
    setSelectedLicense(null);
    handleFetchLicenses();
  }

  const handleCloseDialog = () => {
    setSuccess('');
  }
  const handleOpenEditLicenseModal = (license, type) => {
    if (type === 'add') {
      setAddLicenseModalOpen(true);
    } else {

      setEditLicenseModalOpen(true);
    }
    setSelectedLicense(license);
  }
  /* fine dei due handler per aprire e chiudere la modale di edit licenza */



  /* qui ho i due handler per gestire l'eliminazione di una data licenza */
  const cancelDeleteLicense = () => {
    setSelectedLicenseToBeDeleted(null);
  }


  const generateRandomOrderId = () => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length: 14 }, () => possible.charAt(Math.floor(Math.random() * possible.length))).join('');
  };


  const executeDeleteLicense = async () => {
    setIsLoading(true);
    try {
      const response = await deleteSelectedLicenseFromUser(userId, selectedLicenseToBeDeleted);
      setSuccess(response.message);
      setIsLoading(false);
      handleCloseEditLicenseModal('edit');
      cancelDeleteLicense();

    } catch (error) {
      setIsLoading(false);
      console.log(error);
      console.error(t('error_deleting'), error?.response?.data?.message || error?.message);
      setErrorMessage(`${t('error_deleting')} ${error?.response?.data?.message}` || `${t('error_deleting')}: ${error?.message}`);
      setSelectedLicenseToBeDeleted(null);
      handleFetchLicenses();
    };

  }

  /* fine dei due handler per gestire l'eliminazione di una data licenza */


  /*   con questo apro la dialog di conferma per procedere all'eliminazione di una licenza */
  const handleDeleteLicense = (licenseToBeDeleted) => {
    setSelectedLicenseToBeDeleted(licenseToBeDeleted);
  }

  /*   const handleRenewOrExtend = (license, type) => {
  
      navigate(`/evaluate-order`, { state: { userId, licenseId: license.licenseId, type: type, selectedLicense: license } });
    }; */

  const handleOpenModal = (type) => {
    setLicenseType(type); // directly set the license type
    setNewOrderModalOpen(true); // open the modal for both cases
  };


  return (
    <>
      {user.role === 'admin' && <div className="mt-0 md:mt-8 max-w-3xl w-full m-auto ">
        <div className="w-full mb-4 max-w-3xl">
          <Button type="button" variant="primary" onClick={() => setAddLicenseModalOpen(true)} >
            <span className='flex justify-center items-center' >   <FaPlus className="mr-2" /> {t('add_license')}</span>
          </Button>
        </div>
      </div>}
      {user.role === 'user' && <div className="my-0 md:my-8 max-w-3xl w-full m-auto ">


        <ManageLicensesButtons handleClick={handleOpenModal} />
      </div>
      }

      {licenses?.length > 0 &&
        <LicensesList licenses={licenses} user={user} handleDeleteLicense={handleDeleteLicense} handleOpenEditLicenseModal={handleOpenEditLicenseModal}
          setIsLoading={setIsLoading} setErrorMessage={setErrorMessage}
      /*     handleRenewOrExtend={handleRenewOrExtend} */ loggedUserId={userId} handleFetchLicenses={handleFetchLicenses} />
      }

      <>

        {newOrderModal && <NewOrderModal licenseType={licenseType} isOpen={newOrderModal} userId={userId} setErrorMessage={setErrorMessage} onClose={() => setNewOrderModalOpen(false)} setIsLoading={setIsLoading} />}
      </>
      {editLicenseModalOpen && <EditOrderModal isOpen={editLicenseModalOpen} selectedLicense={selectedLicense} type='edit' setErrorMessage={setErrorMessage} onClose={() => handleCloseEditLicenseModal('edit')}
        setIsLoading={setIsLoading} userId={userId}
        isAdmin={user.role === 'admin'} />}
      {selectedLicenseToBeDeleted && <Dialog message={`Do you want to delete the license ${selectedLicenseToBeDeleted.licenseId} - Order Id: ${selectedLicenseToBeDeleted.orderId}?`} onConfirm={executeDeleteLicense}
        onCancel={cancelDeleteLicense} />}

      <>
        {addLicenseModalOpen && <EditOrderModal isOpen={addLicenseModalOpen}
          type='add'
          selectedLicense={{
            orderId: generateRandomOrderId(),
            currency: '',
            amount: null,
            purchaseDate: new Date().toISOString().split('T')[0],
            licenseValid: true,
            licenseId: '',
            licenseExpireDate: new Date(Date.now() + 31536000000).toISOString().split('T')[0],
          }} setErrorMessage={setErrorMessage} onClose={() => handleCloseEditLicenseModal('add')} setIsLoading={setIsLoading} userId={userId} />}
      </>
      {success !== '' && <ConfirmationDialog message={success} closeDialog={handleCloseDialog} />}

    </>


  );
};

export default Orders;

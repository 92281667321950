import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import LangFlags from './LangFlags';
import './Header.scss';
import ThemeToggle from '../ThemeToggle';

const Header = ({ onMenuToggleBlur }) => {
  const { t } = useTranslation('translation');
  const { isUserLoggedIn, userRole, user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const navbarRef = useRef(null);
  const navContainerRef = useRef(null); // Ref for the area to exclude from click

  const toggleMenu = () => {
    setIsMenuOpen(prevValue => !prevValue);
    onMenuToggleBlur();
  };

  const handleMenuNavigation = (path) => {
    setIsMenuOpen(false);
    if (isMenuOpen) {
      onMenuToggleBlur();
    }
    navigate({ pathname: path }, { state: { userId: user?._id } });
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  // Function to handle clicks outside of navbarRef and navContainerRef
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure menu is open before handling clicks outside
      if (isMenuOpen) {
        if (
          navbarRef.current && !navbarRef.current.contains(event.target) &&
          navContainerRef.current && !navContainerRef.current.contains(event.target)
        ) {
          toggleMenu(); // Close the menu
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen, navbarRef, navContainerRef]); //

  const navLinkTextClass = 'text-lightSecondaryBackground dark:text-white';

  return (
    <header className="header  bg-lightBackground dark:bg-secondaryBackground">
      <div ref={navContainerRef} className="mx-auto flex justify-center items-center p-4 lg:py-4 md:max-w-[1200px] w-full relative">
        <nav className="md:flex md:items-center md:space-x-4 w-full md:w-full">
          <ul className="flex flex-row gap-4 flex-wrap items-center justify-between w-full text-2xl md:text-2xl">
            <li
              onClick={toggleMenu}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300 whitespace-nowrap flex gap-2 items-center justify-center cursor-pointer`}
            >
              <i className="fa-solid fa-bars"></i> <span className='md:block hidden'>{isMenuOpen ? t('close') : 'Menu'}</span>
            </li>
            <li

              onClick={() => handleMenuNavigation('/')}

              className={`${navLinkTextClass} font-bold bg-gradient-to-r from-red-500 via-purple-500 to-indigo-500 bg-clip-text text-transparent`}
            >
              <span

                className="font-bold dark:bg-gradient-to-r dark:from-red-500 dark:via-purple-500 dark:to-indigo-500 dark:bg-clip-text dark:text-transparent  centerLogo cursor-pointer text-lightMainText"
              >
                AE SoftTech Pty Ltd
              </span>
            </li>

            <div>
              {isUserLoggedIn ? (
                <li>
                  <Link
                    to="/logout"
                    className={`${navLinkTextClass} hover:text-blue-600 transition duration-300 flex gap-2 items-center justify-center`}
                  >
                    <span className='md:block hidden'>Logout</span> <i className="fa-solid fa-right-from-bracket"></i>
                  </Link>
                </li>
              ) : (
                <li onClick={() => handleMenuNavigation('/login')}
                  className={`${navLinkTextClass} hover:text-blue-600 transition duration-300 flex gap-2 items-center justify-center cursor-pointer`}
                >
                  <span className='md:block hidden'>Login</span> <i className="fa-solid fa-circle-user"></i>
                </li>
              )}
            </div>
          </ul>
        </nav>
      </div>

      {/* Hidden menu related to menu items */}
      <div className={` h-full absolute left-0 w-full bg-white dark:bg-secondaryBackground transition-all duration-300 ease-in-out ${isMenuOpen ? 'z-50 opacity-100 dark:opacity-90 top-15' : 'h-0 opacity-0 translate-y-0 -z-50'}`}>
        <div className={`max-w-[1200px] m-auto transition-opacity duration-300 ${isMenuOpen ? 'opacity-100 dark:opacity-90' : 'opacity-0'}`}>
          <ul ref={navbarRef} className='p-5 font-normal leading-[5rem] text-4xl w-max'>
            <li
              onClick={() => handleMenuNavigation('/')}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300 whitespace-nowrap  cursor-pointer `}
            >
            </li>
            <li onClick={toggleMenu}>
              <LangFlags />
            </li>
            <li onClick={toggleMenu} className='max-w-[70%]'>
              <ThemeToggle />
            </li>
            <li
              onClick={() => handleMenuNavigation('/')}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300 whitespace-nowrap  cursor-pointer `}
            >
              Home
            </li>
            {isUserLoggedIn && (
              <li
                onClick={() => handleMenuNavigation('/orders')}
                className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
              >
                {t('orders')}
              </li>
            )}

            {userRole === 'user' && isUserLoggedIn && (
              <li
                onClick={() => handleMenuNavigation('/user-info')}
                className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
              >
                {t('personal_info')}
              </li>
            )}
            <li
              onClick={() => handleMenuNavigation('/prices')}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
            >
              {t('prices')}
            </li>
            <li
              onClick={() => handleMenuNavigation('/faq')}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
            >
              {t('FAQ')}
            </li>
            <li
              onClick={() => handleMenuNavigation('/contact')}
              className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
            >
              {t('contact')}
            </li>

            {userRole === 'admin' && (
              <li
                onClick={() => handleMenuNavigation('/logs')}
                className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
              >
                Logs
              </li>
            )}
            {userRole === 'admin' && (
              <li
                onClick={() => handleMenuNavigation('/users')}
                className={`${navLinkTextClass} hover:text-blue-600 transition duration-300  cursor-pointer `}
              >
                {t('users_management')}
              </li>
            )}
          </ul>
        </div>
      </div>

    </header>
  );
};

export default Header;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCustom from '../../Input/SelectCustom';
import { CURRENCIES, LICENSES_TYPE } from '../../../Constants.ts';

const FieldInputOrders = ({ field, value, handleInputChange, toggleLicenseValid, updatedLicense, inputClasses }) => {
  const minNumberAccepted = (fieldType) => {
    return fieldType === 'number' ? 0 : undefined;
  };

  return (
    <div className="mb-4 text-lightCardText dark:text-white">
      <label htmlFor={field.name} className="text-lightCardText dark:text-white text-sm font-bold mb-2">
        {field.placeholder}
      </label>
      {field.type === 'boolean' ? (
        <div className="flex justify-between" onClick={toggleLicenseValid}>
          <p className="font-semibold text-lightCardText dark:text-white">{field.placeholder}</p>
          <p className="text-lightCardText dark:text-white">
            <FontAwesomeIcon
              icon={updatedLicense?.licenseValid ? faCheck : faTimes}
              className={updatedLicense?.licenseValid ? 'text-green-500' : 'text-red-500'}
            />
          </p>
        </div>
      ) : field.type === 'select' || field.type === 'select-license-type' ? (
        <SelectCustom
          index={field.name}
          data={field.type === 'select' ? CURRENCIES : LICENSES_TYPE}
          label={field.label}
          name={field.name}
          value={value}
          inputClasses={inputClasses}
          handleSetValue={handleInputChange}
          placeholder={field.placeholder}
        />
      ) : field.type === 'date' ? (
        <input
          id={field.name}
          type="date"
          required
          value={value ? value.split('T')[0] : ''}
          onChange={handleInputChange}
          className={inputClasses}
        />
      ) : (
        <input
          id={field.name}
          type={field.type}
          placeholder={field.placeholder}
          required={field.name !== 'activationCode'}
          disabled={field.name === 'invoiceId'}
          min={minNumberAccepted(field.type)}
          value={value || ''}
          onChange={handleInputChange}
          className={inputClasses}
        />
      )}
    </div>
  );
};

export default FieldInputOrders;

// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import Home from './components/Home';
import Contact from './components/Contact';
import Login from './components/Login';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import Logout from './components/Logout';
import UserManagement from './components/UsersManagement/UserManagement';
import Footer from './components/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Orders from './components/Orders/Orders';
import EvaluateOrder from './components/Orders/EvaluateOrder';
import PayPalPaymentPage from './components/Payments/PayPal';
import StripePaymentPage from './components/Payments/Stripe';
import SuccessPage from './components/Orders/Success';
import CancelPage from './components/Orders/Cancel';
import UserInfo from './components/UsersManagement/UserInfo';
import ResetPassword from './components/UsersManagement/Password/ResetPassword';
import NotFound from './components/NotFound';
import FAQ from './components/FAQ/FAQ';
import WorkInProgress from './components/WIP';
import LogsPage from './components/Logs/Logs';
import Prices from './components/Prices/Prices';

function App() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleBlurOnMenuOpen = () => {
    setIsMenuOpen((previousValue) => {
      return !previousValue;
    })
  }

  const WORK_IN_PROGRESS = process.env.REACT_APP_WORK_IN_PROGRESS === 'true';
  return (
    <AuthProvider>
      <Router>
        {WORK_IN_PROGRESS ? <WorkInProgress /> :
          <>
            <Header onMenuToggleBlur={handleBlurOnMenuOpen} />
            <main className={`max-w-screen-xxl mx-auto ${isMenuOpen ? 'blur-sm' : ''} m-0 min-h-screen-footer`}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home />} />
                <Route path="/paypal-payment" element={<PrivateRoute roles={['admin', 'user']}>
                  <PayPalPaymentPage />
                </PrivateRoute>} />
                <Route path="/stripe-payment" element={<PrivateRoute roles={['admin', 'user']}>
                  <StripePaymentPage />
                </PrivateRoute>} />
                <Route path="/success" element={<PrivateRoute roles={['admin', 'user']}>
                  <SuccessPage />
                </PrivateRoute>} />
                <Route path="/cancel" element={<PrivateRoute roles={['admin', 'user']}>
                  <CancelPage />
                </PrivateRoute>} />
                <Route path="/evaluate-order" element={<PrivateRoute roles={['admin', 'user']}>
                  <EvaluateOrder />
                </PrivateRoute>} />
                <Route path="/orders" element={<PrivateRoute roles={['admin', 'user']}>
                  <Orders />
                </PrivateRoute>} />
                <Route path="/logs" element={<PrivateRoute roles={['admin']}>
                  <LogsPage />
                </PrivateRoute>} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/prices" element={<Prices />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/users" element={<PrivateRoute roles={['admin']}>
                  <UserManagement />
                </PrivateRoute>} />
                <Route path="/user-info" element={<PrivateRoute roles={['user', 'admin']}>
                  <UserInfo />
                </PrivateRoute>} />
                {/* Catch-all route for 404 page */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </>}
        {!WORK_IN_PROGRESS && <Footer />}
      </Router>
    </AuthProvider>
  );
}

export default App;

export const COUNTRIES = {
  "data": [
    {
      "id": 1,
      "code": "IT",
      "iso3Code": "ITA",
      "label": "Italy",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+39",
      "addressEnabled": true,
      "nationalityLabel": "Italiana",
      "nationalityLabelEn": "Italian",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "it"
    },
    {
      "id": 2,
      "code": "ES",
      "iso3Code": "ESP",
      "label": "Spain",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+34",
      "addressEnabled": true,
      "nationalityLabel": "Spagnola",
      "nationalityLabelEn": "Spanish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "es"
    },
    {
      "id": 3,
      "code": "DE",
      "iso3Code": "DEU",
      "label": "Germany",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+49",
      "addressEnabled": true,
      "nationalityLabel": "Tedesca",
      "nationalityLabelEn": "German",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "de"
    },
    {
      "id": 4,
      "code": "PT",
      "iso3Code": "PRT",
      "label": "Portugal",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+351",
      "addressEnabled": true,
      "nationalityLabel": "Portoghese",
      "nationalityLabelEn": "Portuguese",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "pt"
    },
    {
      "id": 5,
      "code": "NL",
      "iso3Code": "NLD",
      "label": "Netherlands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+31",
      "addressEnabled": true,
      "nationalityLabel": "Olandese",
      "nationalityLabelEn": "Dutch",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "nl"
    },
    {
      "id": 6,
      "code": "FR",
      "iso3Code": "FRA",
      "label": "France",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+33",
      "addressEnabled": true,
      "nationalityLabel": "Francese",
      "nationalityLabelEn": "French",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "fr"
    },
    {
      "id": 7,
      "code": "CH",
      "iso3Code": "CHE",
      "label": "Switzerland",
      "currency": {
        "code": "CHF",
        "symbol": "CHF",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+41",
      "addressEnabled": true,
      "nationalityLabel": "Svizzera",
      "nationalityLabelEn": "Swiss",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "de"
    },
    {
      "id": 8,
      "code": "AT",
      "iso3Code": "AUT",
      "label": "Austria",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+43",
      "addressEnabled": true,
      "nationalityLabel": "Austriaca",
      "nationalityLabelEn": "Austrian",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "de"
    },
    {
      "id": 9,
      "code": "GB",
      "iso3Code": "GBR",
      "label": "United Kingdom",
      "currency": {
        "code": "GBP",
        "symbol": "£",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+44",
      "addressEnabled": true,
      "nationalityLabel": "Britannica",
      "nationalityLabelEn": "British",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 10,
      "code": "HU",
      "iso3Code": "HUN",
      "label": "Hungary",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+36",
      "addressEnabled": true,
      "nationalityLabel": "Ungherese",
      "nationalityLabelEn": "Hungarian",
      "isUe": true,
      "countryLang": "hu"
    },
    {
      "id": 11,
      "code": "IE",
      "iso3Code": "IRL",
      "label": "Ireland",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+353",
      "addressEnabled": true,
      "nationalityLabel": "Irlandese",
      "nationalityLabelEn": "Irish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "en"
    },
    {
      "id": 253,
      "code": "IL",
      "iso3Code": "ISR",
      "label": "Israel",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+972",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Israeli",
      "isUe": false,
      "countryLang": "he"
    },
    {
      "id": 254,
      "code": "AF",
      "iso3Code": "AFG",
      "label": "Afghanistan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+93",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Afghan",
      "isUe": false,
      "countryLang": "fa"
    },
    {
      "id": 255,
      "code": "AL",
      "iso3Code": "ALB",
      "label": "Albania",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+355",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Albanian",
      "isUe": false,
      "countryLang": "sq"
    },
    {
      "id": 256,
      "code": "DZ",
      "iso3Code": "DZA",
      "label": "Algeria",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+213",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Algerian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 257,
      "code": "AS",
      "iso3Code": "ASM",
      "label": "AmericanSamoa",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Samoan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 258,
      "code": "AD",
      "iso3Code": "AND",
      "label": "Andorra",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+376",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Andorran",
      "isUe": false,
      "countryLang": "ca"
    },
    {
      "id": 259,
      "code": "AO",
      "iso3Code": "AGO",
      "label": "Angola",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+244",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Angolan",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 260,
      "code": "AI",
      "iso3Code": "AIA",
      "label": "Anguilla",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Anguillan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 261,
      "code": "AG",
      "iso3Code": "ATG",
      "label": "Antigua and Barbuda",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Antiguan and Barbudan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 262,
      "code": "AR",
      "iso3Code": "ARG",
      "label": "Argentina",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+54",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Argentine",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 263,
      "code": "AM",
      "iso3Code": "ARM",
      "label": "Armenia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+374",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Armenian",
      "isUe": false,
      "countryLang": "hy"
    },
    {
      "id": 264,
      "code": "AW",
      "iso3Code": "ABW",
      "label": "Aruba",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+297",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Aruban",
      "isUe": false,
      "countryLang": "nl"
    },
    {
      "id": 265,
      "code": "AU",
      "iso3Code": "AUS",
      "label": "Australia",
      "currency": {
        "code": "AUD",
        "symbol": "$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+61",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Australian",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 266,
      "code": "AZ",
      "iso3Code": "AZE",
      "label": "Azerbaijan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+994",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Azerbaijani",
      "isUe": false,
      "countryLang": "az"
    },
    {
      "id": 267,
      "code": "BS",
      "iso3Code": "BHS",
      "label": "Bahamas",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bahamian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 268,
      "code": "BH",
      "iso3Code": "BHR",
      "label": "Bahrain",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+973",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bahraini",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 269,
      "code": "BD",
      "iso3Code": "BGD",
      "label": "Bangladesh",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+880",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bangladeshi",
      "isUe": false,
      "countryLang": "bn"
    },
    {
      "id": 270,
      "code": "BB",
      "iso3Code": "BRB",
      "label": "Barbados",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Barbadian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 271,
      "code": "BY",
      "iso3Code": "BLR",
      "label": "Belarus",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+375",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Belarusian",
      "isUe": false,
      "countryLang": "be"
    },
    {
      "id": 272,
      "code": "BE",
      "iso3Code": "BEL",
      "label": "Belgium",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+32",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Belgian",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "nl"
    },
    {
      "id": 273,
      "code": "BZ",
      "iso3Code": "BLZ",
      "label": "Belize",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+501",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Belizean",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 274,
      "code": "BJ",
      "iso3Code": "BEN",
      "label": "Benin",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+229",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Beninese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 275,
      "code": "BM",
      "iso3Code": "BMU",
      "label": "Bermuda",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bermudian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 276,
      "code": "BT",
      "iso3Code": "BTN",
      "label": "Bhutan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+975",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bhutanese",
      "isUe": false,
      "countryLang": "dz"
    },
    {
      "id": 277,
      "code": "BA",
      "iso3Code": "BIH",
      "label": "Bosnia and Herzegovina",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+387",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bosnian",
      "isUe": false,
      "countryLang": "bs"
    },
    {
      "id": 278,
      "code": "BW",
      "iso3Code": "BWA",
      "label": "Botswana",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+267",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Botswanan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 279,
      "code": "BR",
      "iso3Code": "BRA",
      "label": "Brazil",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+55",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Brazilian",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 280,
      "code": "IO",
      "iso3Code": "IOT",
      "label": "British Indian Ocean Territory",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+246",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Briti Indian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 281,
      "code": "BG",
      "iso3Code": "BGR",
      "label": "Bulgaria",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+359",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bulgarian",
      "isUe": true,
      "countryLang": "bg"
    },
    {
      "id": 282,
      "code": "BF",
      "iso3Code": "BFA",
      "label": "Burkina Faso",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+226",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Burkinese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 283,
      "code": "BI",
      "iso3Code": "BDI",
      "label": "Burundi",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+257",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Burundian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 284,
      "code": "KH",
      "iso3Code": "KHM",
      "label": "Cambodia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+855",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cambodian",
      "isUe": false,
      "countryLang": "km"
    },
    {
      "id": 285,
      "code": "CM",
      "iso3Code": "CMR",
      "label": "Cameroon",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+237",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cameroonian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 286,
      "code": "CA",
      "iso3Code": "CAN",
      "label": "Canada",
      "currency": {
        "code": "CAD",
        "symbol": "$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Canadian",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 287,
      "code": "CV",
      "iso3Code": "CPV",
      "label": "Cape Verde",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+238",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cape Verdean",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 288,
      "code": "KY",
      "iso3Code": "CYM",
      "label": "Cayman Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+345",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Caymanian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 289,
      "code": "CF",
      "iso3Code": "CAF",
      "label": "Central African Republic",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+236",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Central African",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 290,
      "code": "TD",
      "iso3Code": "TCD",
      "label": "Chad",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+235",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Chadian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 291,
      "code": "CL",
      "iso3Code": "CHL",
      "label": "Chile",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+56",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Chilean",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 292,
      "code": "CN",
      "iso3Code": "CHN",
      "label": "China",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+86",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Chinese",
      "isUe": false,
      "countryLang": "zh"
    },
    {
      "id": 293,
      "code": "CX",
      "iso3Code": "CXR",
      "label": "Christmas Island",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+61",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Christmas Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 294,
      "code": "CO",
      "iso3Code": "COL",
      "label": "Colombia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+57",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Colombian",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 295,
      "code": "KM",
      "iso3Code": "COM",
      "label": "Comoros",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+269",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Comorian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 296,
      "code": "CG",
      "iso3Code": "COG",
      "label": "Congo",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+242",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Congolese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 297,
      "code": "CK",
      "iso3Code": "COK",
      "label": "Cook Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+682",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cook Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 298,
      "code": "CR",
      "iso3Code": "CRI",
      "label": "Costa Rica",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+506",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Costa Rican",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 299,
      "code": "HR",
      "iso3Code": "HRV",
      "label": "Croatia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+385",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Croatian",
      "isUe": true,
      "countryLang": "hr"
    },
    {
      "id": 300,
      "code": "CU",
      "iso3Code": "CUB",
      "label": "Cuba",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+53",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cuban",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 301,
      "code": "CY",
      "iso3Code": "CYP",
      "label": "Cyprus",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+357",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cypriot",
      "isUe": true,
      "countryLang": "el"
    },
    {
      "id": 302,
      "code": "CZ",
      "iso3Code": "CZE",
      "label": "Czech Republic",
      "currency": {
        "code": "CZK",
        "symbol": "Kč",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+420",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Czech",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "cs"
    },
    {
      "id": 303,
      "code": "DK",
      "iso3Code": "DNK",
      "label": "Denmark",
      "currency": {
        "code": "DKK",
        "symbol": "kr",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+45",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Danish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "da"
    },
    {
      "id": 304,
      "code": "DJ",
      "iso3Code": "DJI",
      "label": "Djibouti",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+253",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Djiboutian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 305,
      "code": "DM",
      "iso3Code": "DMA",
      "label": "Dominica",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Dominican",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 306,
      "code": "DO",
      "iso3Code": "DOM",
      "label": "Dominican Republic",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Dominican",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 307,
      "code": "EC",
      "iso3Code": "ECU",
      "label": "Ecuador",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+593",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ecuadorean",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 308,
      "code": "EG",
      "iso3Code": "EGY",
      "label": "Egypt",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+20",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Egyptian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 309,
      "code": "SV",
      "iso3Code": "SLV",
      "label": "El Salvador",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+503",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Salvadorean",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 310,
      "code": "GQ",
      "iso3Code": "GNQ",
      "label": "Equatorial Guinea",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+240",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Equatoguinean",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 311,
      "code": "ER",
      "iso3Code": "ERI",
      "label": "Eritrea",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+291",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Eritrean",
      "isUe": false,
      "countryLang": "ti"
    },
    {
      "id": 312,
      "code": "EE",
      "iso3Code": "EST",
      "label": "Estonia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+372",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Estonian",
      "isUe": true,
      "countryLang": "et"
    },
    {
      "id": 313,
      "code": "ET",
      "iso3Code": "ETH",
      "label": "Ethiopia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+251",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ethiopian",
      "isUe": false,
      "countryLang": "am"
    },
    {
      "id": 314,
      "code": "FO",
      "iso3Code": "FRO",
      "label": "Faroe Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+298",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Faroe Islanders",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 315,
      "code": "FJ",
      "iso3Code": "FJI",
      "label": "Fiji",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+679",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Fijian",
      "isUe": false,
      "countryLang": "fo"
    },
    {
      "id": 316,
      "code": "FI",
      "iso3Code": "FIN",
      "label": "Finland",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+358",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Finnish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "en"
    },
    {
      "id": 317,
      "code": "GF",
      "iso3Code": "GUF",
      "label": "French Guiana",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+594",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "French Guianan / French Guianese",
      "isUe": false,
      "countryLang": "fi"
    },
    {
      "id": 318,
      "code": "PF",
      "iso3Code": "PYF",
      "label": "French Polynesia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+689",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Polynesian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 319,
      "code": "GA",
      "iso3Code": "GAB",
      "label": "Gabon",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+241",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Gabonese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 320,
      "code": "GM",
      "iso3Code": "GMB",
      "label": "Gambia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+220",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Gambian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 321,
      "code": "GE",
      "iso3Code": "GEO",
      "label": "Georgia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+995",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Georgian",
      "isUe": false,
      "countryLang": "ka"
    },
    {
      "id": 322,
      "code": "GH",
      "iso3Code": "GHA",
      "label": "Ghana",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+233",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ghanaian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 323,
      "code": "GI",
      "iso3Code": "GIB",
      "label": "Gibraltar",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+350",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Gibraltarian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 324,
      "code": "GR",
      "iso3Code": "GRC",
      "label": "Greece",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+30",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Greek",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "el"
    },
    {
      "id": 325,
      "code": "GL",
      "iso3Code": "GRL",
      "label": "Greenland",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+299",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Greenlandic",
      "isUe": false,
      "countryLang": "kl"
    },
    {
      "id": 326,
      "code": "GD",
      "iso3Code": "GRD",
      "label": "Grenada",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Grenadian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 327,
      "code": "GP",
      "iso3Code": "GLP",
      "label": "Guadeloupe",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+590",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guadeloupean",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 328,
      "code": "GU",
      "iso3Code": "GUM",
      "label": "Guam",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guamanian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 329,
      "code": "GT",
      "iso3Code": "GTM",
      "label": "Guatemala",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+502",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guatemalan",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 330,
      "code": "GN",
      "iso3Code": "GIN",
      "label": "Guinea",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+224",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guinean",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 331,
      "code": "GW",
      "iso3Code": "GNB",
      "label": "Guinea-Bissau",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+245",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bissau-Guinean",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 332,
      "code": "GY",
      "iso3Code": "GUY",
      "label": "Guyana",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+595",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guyanese",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 333,
      "code": "HT",
      "iso3Code": "HTI",
      "label": "Haiti",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+509",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Haitian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 334,
      "code": "HN",
      "iso3Code": "HND",
      "label": "Honduras",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+504",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Honduran",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 335,
      "code": "IS",
      "iso3Code": "ISL",
      "label": "Iceland",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+354",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Icelander",
      "isUe": false,
      "countryLang": "zh"
    },
    {
      "id": 336,
      "code": "IN",
      "iso3Code": "IND",
      "label": "India",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+91",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Indian",
      "isUe": false,
      "countryLang": "is"
    },
    {
      "id": 337,
      "code": "ID",
      "iso3Code": "IDN",
      "label": "Indonesia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+62",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Indonesian",
      "isUe": false,
      "countryLang": "hi"
    },
    {
      "id": 338,
      "code": "IQ",
      "iso3Code": "IRQ",
      "label": "Iraq",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+964",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Iraqi",
      "isUe": false,
      "countryLang": "id"
    },
    {
      "id": 339,
      "code": "JM",
      "iso3Code": "JAM",
      "label": "Jamaica",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Jamaican",
      "isUe": false,
      "countryLang": "fa"
    },
    {
      "id": 340,
      "code": "JP",
      "iso3Code": "JPN",
      "label": "Japan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+81",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Japanese",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 341,
      "code": "JO",
      "iso3Code": "JOR",
      "label": "Jordan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+962",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Jordanian",
      "isUe": false,
      "countryLang": "it"
    },
    {
      "id": 342,
      "code": "KZ",
      "iso3Code": "KAZ",
      "label": "Kazakhstan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+7",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Kazakhstani",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 343,
      "code": "KE",
      "iso3Code": "KEN",
      "label": "Kenya",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+254",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Kenyan",
      "isUe": false,
      "countryLang": "ja"
    },
    {
      "id": 344,
      "code": "KI",
      "iso3Code": "KIR",
      "label": "Kiribati",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+686",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "I-Kiribati",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 345,
      "code": "KW",
      "iso3Code": "KWT",
      "label": "Kuwait",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+965",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Kuwaiti",
      "isUe": false,
      "countryLang": "kk"
    },
    {
      "id": 346,
      "code": "KG",
      "iso3Code": "KGZ",
      "label": "Kyrgyzstan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+996",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Kyrgyz, Kyrgyzstani",
      "isUe": false,
      "countryLang": "sw"
    },
    {
      "id": 347,
      "code": "LV",
      "iso3Code": "LVA",
      "label": "Latvia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+371",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Latvian",
      "isUe": true,
      "countryLang": "en"
    },
    {
      "id": 348,
      "code": "LB",
      "iso3Code": "LBN",
      "label": "Lebanon",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+961",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Lebanese",
      "isUe": false,
      "countryLang": "ko"
    },
    {
      "id": 349,
      "code": "LS",
      "iso3Code": "LSO",
      "label": "Lesotho",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+266",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mosotho (singular), Basotho (plural)",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 350,
      "code": "LR",
      "iso3Code": "LBR",
      "label": "Liberia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+231",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Liberian",
      "isUe": false,
      "countryLang": "ky"
    },
    {
      "id": 351,
      "code": "LI",
      "iso3Code": "LIE",
      "label": "Liechtenstein",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+423",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Liechtensteiner",
      "isUe": false,
      "countryLang": "lo"
    },
    {
      "id": 352,
      "code": "LT",
      "iso3Code": "LTU",
      "label": "Lithuania",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+370",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Lithuanian",
      "isUe": true,
      "countryLang": "lv"
    },
    {
      "id": 353,
      "code": "LU",
      "iso3Code": "LUX",
      "label": "Luxembourg",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+352",
      "addressEnabled": true,
      "nationalityLabel": "Luxembourg",
      "nationalityLabelEn": "Luxembourger",
      "isUe": true,
      "countryLang": "ar"
    },
    {
      "id": 354,
      "code": "MG",
      "iso3Code": "MDG",
      "label": "Madagascar",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+261",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Malagasy",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 355,
      "code": "MW",
      "iso3Code": "MWI",
      "label": "Malawi",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+265",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Malawian",
      "isUe": false,
      "countryLang": "it"
    },
    {
      "id": 356,
      "code": "MY",
      "iso3Code": "MYS",
      "label": "Malaysia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+60",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Malaysian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 357,
      "code": "MV",
      "iso3Code": "MDV",
      "label": "Maldives",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+960",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Maldivian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 358,
      "code": "ML",
      "iso3Code": "MLI",
      "label": "Mali",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+223",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Malian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 359,
      "code": "MT",
      "iso3Code": "MLT",
      "label": "Malta",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+356",
      "addressEnabled": true,
      "nationalityLabel": "Maltese",
      "nationalityLabelEn": "Maltese",
      "isUe": true,
      "countryLang": "en"
    },
    {
      "id": 360,
      "code": "MH",
      "iso3Code": "MHL",
      "label": "Marshall Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+692",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Marshallese",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 361,
      "code": "MQ",
      "iso3Code": "MTQ",
      "label": "Martinique",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+596",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Martinican",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 362,
      "code": "MR",
      "iso3Code": "MRT",
      "label": "Mauritania",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+222",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mauritanian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 363,
      "code": "MU",
      "iso3Code": "MUS",
      "label": "Mauritius",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+230",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mauritian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 364,
      "code": "YT",
      "iso3Code": "MYT",
      "label": "Mayotte",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+262",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mahoran",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 365,
      "code": "MX",
      "iso3Code": "MEX",
      "label": "Mexico",
      "currency": {
        "code": "MXN",
        "symbol": "$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+52",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mexican",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 366,
      "code": "MC",
      "iso3Code": "MCO",
      "label": "Monaco",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+377",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Monacan",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 367,
      "code": "MN",
      "iso3Code": "MNG",
      "label": "Mongolia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+976",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mongolian",
      "isUe": false,
      "countryLang": "mn"
    },
    {
      "id": 368,
      "code": "ME",
      "iso3Code": "MNE",
      "label": "Montenegro",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+382",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Montenegrin",
      "isUe": false,
      "countryLang": "sr"
    },
    {
      "id": 369,
      "code": "MS",
      "iso3Code": "MSR",
      "label": "Montserrat",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Montserratian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 370,
      "code": "MA",
      "iso3Code": "MAR",
      "label": "Morocco",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+212",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Moroccan",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 371,
      "code": "MM",
      "iso3Code": "MMR",
      "label": "Myanmar",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+95",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Burmese",
      "isUe": false,
      "countryLang": "my"
    },
    {
      "id": 372,
      "code": "NA",
      "iso3Code": "NAM",
      "label": "Namibia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+264",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Namibian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 373,
      "code": "NR",
      "iso3Code": "NRU",
      "label": "Nauru",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+674",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Nauruan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 374,
      "code": "NP",
      "iso3Code": "NPL",
      "label": "Nepal",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+977",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Nepalese",
      "isUe": false,
      "countryLang": "ne"
    },
    {
      "id": 375,
      "code": "AN",
      "iso3Code": "ANT",
      "label": "Netherlands Antilles",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+599",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Netherlands Antillea",
      "isUe": false,
      "countryLang": "nl"
    },
    {
      "id": 376,
      "code": "NC",
      "iso3Code": "NCL",
      "label": "New Caledonia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+687",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "New Caledonian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 377,
      "code": "NZ",
      "iso3Code": "NZL",
      "label": "New Zealand",
      "currency": {
        "code": "NZD",
        "symbol": "$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+64",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "New Zealander",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 378,
      "code": "NI",
      "iso3Code": "NIC",
      "label": "Nicaragua",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+505",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Nicaraguan",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 379,
      "code": "NE",
      "iso3Code": "NER",
      "label": "Niger",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+227",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Nigerien",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 380,
      "code": "NG",
      "iso3Code": "NGA",
      "label": "Nigeria",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+234",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Nigerian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 381,
      "code": "NU",
      "iso3Code": "NIU",
      "label": "Niue",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+683",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Niuean",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 382,
      "code": "NF",
      "iso3Code": "NFK",
      "label": "Norfolk Island",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+672",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Norfolk Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 383,
      "code": "MP",
      "iso3Code": "MNP",
      "label": "Northern Mariana Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Northern Mariana Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 384,
      "code": "NO",
      "iso3Code": "NOR",
      "label": "Norway",
      "currency": {
        "code": "NOK",
        "symbol": "kr",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+47",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Norwegian",
      "enableKlarna": true,
      "isUe": false,
      "countryLang": "no"
    },
    {
      "id": 385,
      "code": "OM",
      "iso3Code": "OMN",
      "label": "Oman",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+968",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Omani",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 386,
      "code": "PK",
      "iso3Code": "PAK",
      "label": "Pakistan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+92",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Pakistani",
      "isUe": false,
      "countryLang": "ur"
    },
    {
      "id": 387,
      "code": "PW",
      "iso3Code": "PLW",
      "label": "Palau",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+680",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Palauan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 388,
      "code": "PA",
      "iso3Code": "PAN",
      "label": "Panama",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+507",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Panamanian",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 389,
      "code": "PG",
      "iso3Code": "PNG",
      "label": "Papua New Guinea",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+675",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guinean",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 390,
      "code": "PY",
      "iso3Code": "PRY",
      "label": "Paraguay",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+595",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Paraguayan",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 391,
      "code": "PE",
      "iso3Code": "PER",
      "label": "Peru",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+51",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Peruvian",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 392,
      "code": "PH",
      "iso3Code": "PHL",
      "label": "Philippines",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+63",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Filipino",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 393,
      "code": "PL",
      "iso3Code": "POL",
      "label": "Poland",
      "currency": {
        "code": "PLN",
        "symbol": "zł",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+48",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Polish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "pl"
    },
    {
      "id": 394,
      "code": "PR",
      "iso3Code": "PRI",
      "label": "Puerto Rico",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Puerto Rican",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 395,
      "code": "QA",
      "iso3Code": "QAT",
      "label": "Qatar",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+974",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Qatari",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 396,
      "code": "RO",
      "iso3Code": "ROU",
      "label": "Romania",
      "currency": {
        "code": "RON",
        "symbol": "lei",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+40",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Romanian",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "ro"
    },
    {
      "id": 397,
      "code": "RW",
      "iso3Code": "RWA",
      "label": "Rwanda",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+250",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Rwandan",
      "isUe": false,
      "countryLang": "rw"
    },
    {
      "id": 398,
      "code": "WS",
      "iso3Code": "WSM",
      "label": "Samoa",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+685",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Samoan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 399,
      "code": "SM",
      "iso3Code": "SMR",
      "label": "San Marino",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+378",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Sammarinese",
      "isUe": false,
      "countryLang": "it"
    },
    {
      "id": 400,
      "code": "SA",
      "iso3Code": "SAU",
      "label": "Saudi Arabia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+966",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saudi Arabian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 401,
      "code": "SN",
      "iso3Code": "SEN",
      "label": "Senegal",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+221",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Senegalese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 402,
      "code": "RS",
      "iso3Code": "SRB",
      "label": "Serbia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+381",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Serbian",
      "isUe": false,
      "countryLang": "sr"
    },
    {
      "id": 403,
      "code": "SC",
      "iso3Code": "SYC",
      "label": "Seychelles",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+248",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Seychellois",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 404,
      "code": "SL",
      "iso3Code": "SLE",
      "label": "Sierra Leone",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+232",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Sierra Leonian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 405,
      "code": "SG",
      "iso3Code": "SGP",
      "label": "Singapore",
      "currency": {
        "code": "SGD",
        "symbol": "S$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+65",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Singaporean",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 406,
      "code": "SK",
      "iso3Code": "SVK",
      "label": "Slovakia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+421",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Slovak",
      "isUe": true,
      "countryLang": "sk"
    },
    {
      "id": 407,
      "code": "SI",
      "iso3Code": "SVN",
      "label": "Slovenia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+386",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Slovenian",
      "isUe": true,
      "countryLang": "sl"
    },
    {
      "id": 408,
      "code": "SB",
      "iso3Code": "SLB",
      "label": "Solomon Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+677",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Slomoni",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 409,
      "code": "ZA",
      "iso3Code": "ZAF",
      "label": "South Africa",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+27",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "South African",
      "isUe": false,
      "countryLang": "af"
    },
    {
      "id": 410,
      "code": "GS",
      "iso3Code": "SGS",
      "label": "South Georgia and the South Sandwich Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+500",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "South Georgian, Sandwich Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 411,
      "code": "LK",
      "iso3Code": "LKA",
      "label": "Sri Lanka",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+94",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Sri Lankan",
      "isUe": false,
      "countryLang": "si"
    },
    {
      "id": 412,
      "code": "SD",
      "iso3Code": "SDN",
      "label": "Sudan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+249",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Sudanese",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 413,
      "code": "SR",
      "iso3Code": "SUR",
      "label": "Surilabel",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+597",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Surilabelse",
      "isUe": false,
      "countryLang": "nl"
    },
    {
      "id": 414,
      "code": "SZ",
      "iso3Code": "SWZ",
      "label": "Swaziland",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+268",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Swazi",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 415,
      "code": "SE",
      "iso3Code": "SWE",
      "label": "Sweden",
      "currency": {
        "code": "SEK",
        "symbol": "kr",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+46",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Swedish",
      "enableKlarna": true,
      "isUe": true,
      "countryLang": "sv"
    },
    {
      "id": 416,
      "code": "TJ",
      "iso3Code": "TJK",
      "label": "Tajikistan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+992",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tajik, Tajikistani",
      "isUe": false,
      "countryLang": "tg"
    },
    {
      "id": 417,
      "code": "TH",
      "iso3Code": "THA",
      "label": "Thailand",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+66",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Thai",
      "isUe": false,
      "countryLang": "th"
    },
    {
      "id": 418,
      "code": "TG",
      "iso3Code": "TGO",
      "label": "Togo",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+228",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Togolese",
      "isUe": false,
      "countryLang": "tg"
    },
    {
      "id": 419,
      "code": "TK",
      "iso3Code": "TKL",
      "label": "Tokelau",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+690",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tokelauan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 420,
      "code": "TO",
      "iso3Code": "TON",
      "label": "Tonga",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+676",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tongan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 421,
      "code": "TT",
      "iso3Code": "TTO",
      "label": "Trinidad and Tobago",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Trinidadian and Tobagonian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 422,
      "code": "TN",
      "iso3Code": "TUN",
      "label": "Tunisia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+216",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tunisian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 423,
      "code": "TR",
      "iso3Code": "TUR",
      "label": "Turkey",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+90",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Turkish",
      "isUe": false,
      "countryLang": "tr"
    },
    {
      "id": 424,
      "code": "TM",
      "iso3Code": "TKM",
      "label": "Turkmenistan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+993",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Turkoman",
      "isUe": false,
      "countryLang": "tk"
    },
    {
      "id": 425,
      "code": "TC",
      "iso3Code": "TCA",
      "label": "Turks and Caicos Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Turkmenistani, Turkmen, Turkmenian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 426,
      "code": "TV",
      "iso3Code": "TUV",
      "label": "Tuvalu",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+688",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tuvaluan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 427,
      "code": "UG",
      "iso3Code": "UGA",
      "label": "Uganda",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+256",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ugandan",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 428,
      "code": "UA",
      "iso3Code": "UKR",
      "label": "Ukraine",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+380",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ukrainian",
      "isUe": false,
      "countryLang": "uk"
    },
    {
      "id": 429,
      "code": "AE",
      "iso3Code": "ARE",
      "label": "United Arab Emirates",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+971",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Emirati",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 430,
      "code": "US",
      "iso3Code": "USA",
      "label": "United States of America (USA)",
      "currency": {
        "code": "USD",
        "symbol": "$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": false
      },
      "phonePrefix": "+1",
      "addressEnabled": true,
      "nationalityLabel": "Americana",
      "nationalityLabelEn": "American",
      "enableKlarna": false,
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 431,
      "code": "UY",
      "iso3Code": "URY",
      "label": "Uruguay",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+598",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Uruguayan",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 432,
      "code": "UZ",
      "iso3Code": "UZB",
      "label": "Uzbekistan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+998",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Uzbek, Uzbekistani",
      "isUe": false,
      "countryLang": "uz"
    },
    {
      "id": 433,
      "code": "VU",
      "iso3Code": "VUT",
      "label": "Vanuatu",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+678",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ni-Vanuatu and Vanuatuan",
      "isUe": false,
      "countryLang": "bi"
    },
    {
      "id": 434,
      "code": "WF",
      "iso3Code": "WLF",
      "label": "Wallis and Futuna",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+681",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Wallisian and Futunan",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 435,
      "code": "YE",
      "iso3Code": "YEM",
      "label": "Yemen",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+967",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Yemeni",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 436,
      "code": "ZM",
      "iso3Code": "ZMB",
      "label": "Zambia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+260",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Zambian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 437,
      "code": "ZW",
      "iso3Code": "ZWE",
      "label": "Zimbabwe",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+263",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Zimbabwean",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 438,
      "code": "BO",
      "iso3Code": "BOL",
      "label": "Bolivia, Plurinational State of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+591",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bolivian",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 439,
      "code": "BN",
      "iso3Code": "BRN",
      "label": "Brunei Darussalam",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+673",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Bruneian",
      "isUe": false,
      "countryLang": "ms"
    },
    {
      "id": 440,
      "code": "CC",
      "iso3Code": "CCK",
      "label": "Cocos (Keeling) Islands",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+61",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Cocos Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 441,
      "code": "CD",
      "iso3Code": "COD",
      "label": "Congo, The Democratic Republic of the",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+243",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Congolese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 442,
      "code": "CI",
      "iso3Code": "CIV",
      "label": "Cote d'Ivoire",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+225",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Ivorian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 443,
      "code": "FK",
      "iso3Code": "FLK",
      "label": "Falkland Islands (Malvinas)",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+500",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Falklander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 444,
      "code": "GG",
      "iso3Code": "GGY",
      "label": "Guernsey",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+44",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Guernseyman, Guernseywoman",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 445,
      "code": "VA",
      "iso3Code": "VAT",
      "label": "Holy See (Vatican City State)",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+379",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Holy See",
      "isUe": false,
      "countryLang": "la"
    },
    {
      "id": 446,
      "code": "HK",
      "iso3Code": "HKG",
      "label": "Hong Kong",
      "currency": {
        "code": "HKD",
        "symbol": "HK$",
        "supportsIncomingBankTransfers": false,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+852",
      "addressEnabled": true,
      "nationalityLabel": "",
      "nationalityLabelEn": "Hongkongers",
      "isUe": false,
      "countryLang": "zh"
    },
    {
      "id": 447,
      "code": "IR",
      "iso3Code": "IRN",
      "label": "Iran, Islamic Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+98",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Iranian",
      "isUe": false,
      "countryLang": "fa"
    },
    {
      "id": 448,
      "code": "IM",
      "iso3Code": "IMN",
      "label": "Isle of Man",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+44",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Manx",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 449,
      "code": "JE",
      "iso3Code": "JEY",
      "label": "Jersey",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+44",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "erseyman, Jerseywoman",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 450,
      "code": "KP",
      "iso3Code": "PRK",
      "label": "Korea, Democratic People's Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+850",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "North Korean",
      "isUe": false,
      "countryLang": "ko"
    },
    {
      "id": 451,
      "code": "KR",
      "iso3Code": "KOR",
      "label": "Korea, Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+82",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "South Korean",
      "isUe": false,
      "countryLang": "ko"
    },
    {
      "id": 452,
      "code": "LA",
      "iso3Code": "LAO",
      "label": "Lao People's Democratic Republic",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+856",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Laotian",
      "isUe": false,
      "countryLang": "lo"
    },
    {
      "id": 453,
      "code": "LY",
      "iso3Code": "LBY",
      "label": "Libyan Arab Jamahiriya",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+218",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Libyan",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 454,
      "code": "MO",
      "iso3Code": "MAC",
      "label": "Macao",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+853",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Macanese",
      "isUe": false,
      "countryLang": "zh"
    },
    {
      "id": 455,
      "code": "MK",
      "iso3Code": "MKD",
      "label": "Macedonia, The Former Yugoslav Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+389",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Macedonian",
      "isUe": false,
      "countryLang": "mk"
    },
    {
      "id": 456,
      "code": "FM",
      "iso3Code": "FSM",
      "label": "Micronesia, Federated States of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+691",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Micronesian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 457,
      "code": "MD",
      "iso3Code": "MDA",
      "label": "Moldova, Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+373",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Moldovan",
      "isUe": false,
      "countryLang": "ro"
    },
    {
      "id": 458,
      "code": "MZ",
      "iso3Code": "MOZ",
      "label": "Mozambique",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+258",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Mozambican",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 459,
      "code": "PS",
      "iso3Code": "PSE",
      "label": "Palestinian Territory, Occupied",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+970",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Palestinians",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 460,
      "code": "PN",
      "iso3Code": "PCN",
      "label": "Pitcairn",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+872",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Pitcairn Islanders",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 461,
      "code": "RE",
      "iso3Code": "REU",
      "label": "Réunion",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+262",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Réunionese",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 462,
      "code": "RU",
      "iso3Code": "RUS",
      "label": "Russia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+7",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Russian",
      "isUe": false,
      "countryLang": "ru"
    },
    {
      "id": 463,
      "code": "BL",
      "iso3Code": "BLM",
      "label": "Saint Barthélemy",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+590",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Barthélemois",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 464,
      "code": "SH",
      "iso3Code": "SHN",
      "label": "Saint Helena, Ascension and Tristan Da Cunha",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+290",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saint Helena Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 465,
      "code": "KN",
      "iso3Code": "KNA",
      "label": "Saint Kitts and Nevis",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Kittitian, Nevisian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 466,
      "code": "LC",
      "iso3Code": "LCA",
      "label": "Saint Lucia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saint Lucian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 467,
      "code": "MF",
      "iso3Code": "MAF",
      "label": "Saint Martin",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+590",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saint Martiner",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 468,
      "code": "PM",
      "iso3Code": "SPM",
      "label": "Saint Pierre and Miquelon",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+508",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saint-Pierrais, Miquelonnais, Pierrian",
      "isUe": false,
      "countryLang": "fr"
    },
    {
      "id": 469,
      "code": "VC",
      "iso3Code": "VCT",
      "label": "Saint Vincent and the Grenadines",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Saint Vincentian or Vincentian",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 470,
      "code": "ST",
      "iso3Code": "STP",
      "label": "Sao Tome and Principe",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+239",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Sao Tomean",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 471,
      "code": "SO",
      "iso3Code": "SOM",
      "label": "Somalia",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+252",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Somali",
      "isUe": false,
      "countryLang": "so"
    },
    {
      "id": 472,
      "code": "SJ",
      "iso3Code": "SJM",
      "label": "Svalbard and Jan Mayen",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+47",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Svalbard Norwegian",
      "isUe": false,
      "countryLang": "no"
    },
    {
      "id": 473,
      "code": "SY",
      "iso3Code": "SYR",
      "label": "Syrian Arab Republic",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+963",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Syrian",
      "isUe": false,
      "countryLang": "ar"
    },
    {
      "id": 474,
      "code": "TW",
      "iso3Code": "TWN",
      "label": "Taiwan",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+886",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Taiwanese",
      "isUe": false,
      "countryLang": "zh"
    },
    {
      "id": 475,
      "code": "TZ",
      "iso3Code": "TZA",
      "label": "Tanzania, United Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+255",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Tanzanian",
      "isUe": false,
      "countryLang": "sw"
    },
    {
      "id": 476,
      "code": "TL",
      "iso3Code": "TLS",
      "label": "Timor-Leste",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+670",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Timorese",
      "isUe": false,
      "countryLang": "pt"
    },
    {
      "id": 477,
      "code": "VE",
      "iso3Code": "VEN",
      "label": "Venezuela, Bolivarian Republic of",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+58",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Venezuelan",
      "isUe": false,
      "countryLang": "es"
    },
    {
      "id": 478,
      "code": "VN",
      "iso3Code": "VNM",
      "label": "Viet Nam",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+84",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Vietlabelse",
      "isUe": false,
      "countryLang": "vi"
    },
    {
      "id": 479,
      "code": "VG",
      "iso3Code": "VGB",
      "label": "Virgin Islands, British",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "British Virgin Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 480,
      "code": "VI",
      "iso3Code": "VIR",
      "label": "Virgin Islands, U.S.",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+1",
      "addressEnabled": false,
      "nationalityLabel": "",
      "nationalityLabelEn": "Virgin Islander",
      "isUe": false,
      "countryLang": "en"
    },
    {
      "id": 481,
      "code": "XK",
      "iso3Code": "KOS",
      "label": "Kosovo",
      "currency": {
        "code": "EUR",
        "symbol": "€",
        "supportsIncomingBankTransfers": true,
        "supportsOutgoingBankTransfers": true
      },
      "phonePrefix": "+383",
      "addressEnabled": false,
      "nationalityLabel": " ",
      "nationalityLabelEn": " ",
      "isUe": false,
      "countryLang": "en"
    }
  ]
}
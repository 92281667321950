import enFlag from '../../assets/flags/en-flag.png';
import itFlag from '../../assets/flags/it-flag.png';
import deFlag from '../../assets/flags/de-flag.png';
import esFlag from '../../assets/flags/es-flag.png';
import frFlag from '../../assets/flags/fr-flag.png';
import { useTranslation } from 'react-i18next';

const LangFlags = ({ className }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const flagMap = {
    en: enFlag,
    it: itFlag,
    de: deFlag,
    es: esFlag,
    fr: frFlag,
  };


  const flags = ['en', 'it', 'de', 'es', 'fr'];
  return (
    <div className={`language-switcher ${className}`}>
      {flags.map((flag) => (
        <span key={flag} onClick={() => changeLanguage(flag)}>
          <img src={flagMap[flag]} alt={flag} />
        </span>
      ))}
    </div>
  );
};

export default LangFlags;

import React from 'react';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';

const ErrorDialog = ({ errorMessage, closeDialog }) => {

  const handleCloseDialog = () => {
    closeDialog();
  }

  useDisableOverflow(errorMessage !== '');

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]  "
      onClick={handleCloseDialog}
    >
      <div className="bg-white dark:bg-mainBackground rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 border-2 border-blue-500">
        <p className="text-lg text-lightMainText dark:text-headingText font-semibold text-center mb-4">{errorMessage}</p>
        <div className="flex justify-center space-x-4">
          <Button
            onClick={handleCloseDialog}
            variant='primary'
          >
            OK
          </Button>

        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { addNewLicenseToUser, getLastInvoiceId, setNextInvoiceId, updateLicenseFromLicenses } from '../../api/api';
import Button from '../Button';
import { EDIT_ADD_ORDER_FIELDS } from '../../Constants.ts';
import useDisableOverflow from '../../hooks/useDisableOverflow.js';
import { useTranslation } from 'react-i18next';
import FieldInputOrders from './FieldInput/FieldInputOrders.js';

// Make sure to set the app root element for accessibility
Modal.setAppElement('#root');

const EditOrderModal = ({ isOpen, onClose, setIsLoading, setErrorMessage, selectedLicense, userId, isAdmin, type = 'edit' }) => {
  const { t } = useTranslation('translation');
  const [updatedLicense, setUpdatedLicense] = useState(selectedLicense);
  const originalLicenseId = selectedLicense.licenseId;
  const originalOrderId = selectedLicense.orderId;
  const inputClasses = ' ';

  useEffect(() => {
    if (type === 'add') {
      handleLastInvoiceId();
    }
    if (selectedLicense) {
      setUpdatedLicense(selectedLicense);
    }
  }, [isOpen]);

  useDisableOverflow(isOpen);

  const handleModalClose = () => {
    onClose();
  };

  const handleLastInvoiceId = async () => {
    const response = await getLastInvoiceId();
    setUpdatedLicense((prev) => ({ ...prev, invoiceId: response.lastSeq + 1 }));
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setUpdatedLicense((prev) => ({ ...prev, [field.name]: value }));
  };

  const toggleLicenseValid = () => {
    setUpdatedLicense((prev) => ({ ...prev, licenseValid: !prev.licenseValid }));
  };

  const handleUpdateLicense = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (type === 'edit') {
        await updateLicenseFromLicenses(updatedLicense, originalLicenseId, userId, originalOrderId);
      } else if (type === 'add') {
        await addNewLicenseToUser(userId, updatedLicense);
        await setNextInvoiceId();
      }
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(` ${t('error_license_update')}: ${error?.response?.data?.message}`);
      onClose()
    }
  };

  const isFormValid = () => {
    const { currency, amount, purchaseDate, licenseId, licenseExpireDate, licenseType } = updatedLicense || {};
    return currency && amount && purchaseDate && licenseId && licenseExpireDate && licenseType;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Edit License Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground overflow-hidden rounded-lg text-left outline-none sm:max-w-lg sm:w-full w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto"
    >
      <div className="relative p-6">
        <h1 className=" text-lightMainText dark:gradient-text text-3xl font-bold">{type === 'edit' ? t('edit_license') : t('add_license')}</h1>
        <form onSubmit={handleUpdateLicense} className="space-y-4">
          <span className="absolute top-0 right-0 m-4 text-gray-500 hover:text-white focus:outline-none cursor-pointer" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </span>

          {EDIT_ADD_ORDER_FIELDS.map((field) => (
            <FieldInputOrders
              key={field.name}
              field={field}
              value={updatedLicense[field.name]}
              handleInputChange={(e) => handleInputChange(e, field)}
              toggleLicenseValid={toggleLicenseValid}
              updatedLicense={updatedLicense}
              inputClasses={inputClasses}
            />
          ))}

          <div className="flex justify-end">
            <Button variant={!isFormValid() ? 'disabled' : 'primary'} type="submit" disabled={!isFormValid()}>
              {type === 'edit' ? t('update_license') : t('add_license')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditOrderModal;

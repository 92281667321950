import React, { useContext, useState, useCallback } from 'react';
import Modal from 'react-modal';
import Button from '../../Button';
import useDisableOverflow from '../../../hooks/useDisableOverflow';
import { requestNewPassword } from '../../../api/api';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import SignUpModal from '../../SignUp/SignUp';

Modal.setAppElement('#root');

const ResetPasswordModal = ({ isOpen, onClose, userId }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ message: '', error: '' });
  const [showRegisterButton, setShowRegisterButton] = useState(false);
  const { setIsLoading, login, setErrorMessage } = useContext(AuthContext);
  const [signUp, setSignUp] = useState(false);


  useDisableOverflow(isOpen);
  const { t } = useTranslation('translation');

  const handleChange = (e) => setEmail(e.target.value.toLowerCase().trim());

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await requestNewPassword(email);
      setStatus({ message: response.message, error: '' });
      setTimeout(() => {
        onClose();
        setIsLoading(false);
      }, 1000);
    } catch (err) {
      console.error(err);
      if (err.status === 404) {
        setShowRegisterButton(true);
      }
      setStatus({ message: '', error: err?.response?.data?.message + ' please register using the button below' });
      setIsLoading(false);
    }
  }, [email, onClose, setIsLoading]);

  const handleModalClose = useCallback(() => {
    setSignUp(false);
    onClose();
  }, [onClose]);

  const handleRegistrationClick = () => {
    setShowRegisterButton(false);
    setSignUp(true);
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Reset Password Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground  overflow-hidden rounded-lg text-left outline-none sm:max-w-lg sm:w-full w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto"
    >
      <div className="p-6 relative">
        <div className="flex-grow flex items-center justify-start flex-col gap-4">
          <h1 className="text-3xl  text-lightMainText dark:gradient-text  font-bold">{t('request_new_password')}</h1>
          <form onSubmit={handleSubmit} className="text-white text-xl w-full">
            <label htmlFor="email" className="text-white text-sm font-bold mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={handleChange}
              placeholder={t('enter_email')}
              required
              className="mb-4 border-blue-500"
            />
            {<Button variant="primary" type="submit">Password Reset</Button>}
            {status.message && <p className="text-green-500 text-lg">{status.message}</p>}
            {status.error && <p className="text-red-500 text-lg">{status.error}</p>}
            {showRegisterButton && <Button variant="primary" onClick={handleRegistrationClick}>Go To {t('signup')}</Button>}
            {signUp && (
              <SignUpModal
                isOpen={signUp}
                setErrorMessage={setErrorMessage}
                onClose={() => { setSignUp(false) }}
                setIsLoading={setIsLoading}
                login={login}
              />
            )}
          </form>
        </div>
      </div>
    </Modal>

  );
};

export default ResetPasswordModal;

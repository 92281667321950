import React from 'react';
import { REGEX_TYPE } from '../../../Constants.ts';
import InputPassword from '../../Input/InputPassword.js';
import FieldError from '../../Input/FieldError.js';

const PasswordInput = ({ value, setFormData, setFieldErrors, fieldErrors }) => {
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const isValidPassword = REGEX_TYPE.PASSWORD.test(password);

    setFormData((prevData) => ({ ...prevData, password }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, password: !isValidPassword }));
  };

  return (
    <div>
      <InputPassword
        inputClasses={`border-blue-500 ${fieldErrors['password'] ? 'border-red-500' : ''}`}
        handleSetValue={handlePasswordChange}
      />
      <FieldError fieldName="password" fieldErrors={fieldErrors} />
    </div>
  );
};

export default PasswordInput;

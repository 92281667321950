// utils/validation.js
export const validateField = (field, value) => {
  if (field.regex && !new RegExp(field.regex).test(value)) {
    return `${field.name}_invalid`; // Return the key for the error message
  }
  return null;
};

export const validateForm = (fields, formData) => {
  const errors = {};
  fields.forEach((field) => {
    const error = validateField(field, formData[field.name]);
    if (error) errors[field.name] = error;
  });
  return errors;
};

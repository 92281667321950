import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../Button';
import { AuthContext } from '../../context/AuthContext';

const CancelPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const handleViewOrders = () => {
    navigate(`/orders`, { state: { userId: user?._id } });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen-footer bg-white dark:bg-mainBackground">
      <div className="bg-white dark:bg-mainBackground rounded-lg shadow-lg text-center p-5">
        <div className="flex items-center justify-center mb-6">
          <div className="bg-red-500  text-secondaryText dark:text-white p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
        <h1 className="text-2xl font-bold text-lightMainText dark:text-headingText mb-4">ORDER NOT COMPLETED</h1>
        <p className=" text-secondaryText dark:text-white mb-2 text-xl">Unfortunately, your order was not successful.</p>
        <p className=" text-secondaryText dark:text-white text-sm mb-6">Please try again or view your existing orders.</p>
        <Button
          onClick={handleViewOrders}
          variant="primary"
        >
          View orders
        </Button>
      </div>
    </div>
  );
};

export default CancelPage;

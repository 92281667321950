import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../Button';
import { createUsers } from '../../api/api';
import useDisableOverflow from '../../hooks/useDisableOverflow';
import { LANGUAGES, SIGN_UP_FIELDS } from '../../Constants.ts';
import SelectCustom from '../Input/SelectCustom.js';
import { useTranslation } from 'react-i18next';
import { validateField, validateForm } from '../../utils/validation.js';
import FieldInputSignUp from './FieldInputSignUp.js';

Modal.setAppElement('#root');

const SignUpModal = ({ isOpen, onClose, setIsLoading, setErrorMessage, login }) => {
  const { t } = useTranslation('translation');
  const [formData, setFormData] = useState({ email: '', firstName: '', lastName: '', password: '', role: 'user', language: 'en' });
  const [showPassword, setShowPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  useDisableOverflow(isOpen);

  const handleInputChange = (e, field) => {
    let { value } = e.target;

    // Convert email to lowercase
    if (field.name === 'email') {
      value = value.toLowerCase();
    }

    setFormData((prevData) => ({ ...prevData, [field.name]: value }));

    if (field.regex) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, [field.name]: validateField(field, value) }));
    }
  };

  const createUser = async () => {
    setIsLoading(true);
    try {
      await createUsers(formData);
      await login(formData.email, formData.password);
      setFormData({ email: '', password: '', role: 'user', language: 'en' });
      window.location.href = '/'; // Redirect after signup
    } catch (error) {
      console.error('Error creating user:', error?.response?.data.message ?? error?.response?.data);
      setErrorMessage(`Error creating user: ${error?.response?.data.message ?? error?.response?.data}`);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    const errors = validateForm(SIGN_UP_FIELDS, formData);
    setFieldErrors(errors);

    if (!Object.values(errors).some(Boolean)) {
      createUser();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="User Signup Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground overflow-hidden rounded-lg text-left outline-none w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto"
    >
      <div className="p-6 relative">
        <h1 className=" text-lightMainText dark:gradient-text  text-3xl font-bold">{t('create_user')}</h1>
        <form onSubmit={handleCreateUser} className="space-y-4">
          <span className="absolute top-0 right-0 m-4 text-gray-500 hover:text-lightCardText dark:text-white  focus:outline-none cursor-pointer" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </span>

          {SIGN_UP_FIELDS.map((field) =>
            field.type === 'select-language' ? (
              <div className="relative text-lightCardText dark:text-white " key={field.name}>
                <label htmlFor={field.name} className="text-lightCardText dark:text-white  text-sm font-bold mb-2">
                  {t(field.name)}
                </label>
                <SelectCustom
                  data={LANGUAGES}
                  label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  inputClasses="border-blue-500"
                  handleSetValue={(e) => handleInputChange(e, field)}
                  placeholder={t(field.transl)}
                />
              </div>
            ) : (
              <FieldInputSignUp
                key={field.name}
                field={field}
                formData={formData}
                handleInputChange={handleInputChange}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                fieldErrors={fieldErrors}
                t={t}
              />
            )
          )}

          <div className="flex justify-end">
            <Button variant={'primary'} type="submit">
              {t('create_user')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignUpModal;

import React from 'react';
import Button from './Button';

const Pagination = ({ totalPages, currentPage, handlePageChangeClicked }) => {
  // Determine the range of pages to show
  const startPage = Math.max(currentPage, 1);

  return (
    <>
      {totalPages > 1 && (
        <nav className="w-full flex justify-center items-center my-4" aria-label="Page navigation">
          <ul className="inline-flex -space-x-px text-base h-10 items-center justify-center  m-auto max-w-full md:max-w-3xl gap-1">


            {/* Previous Button */}
            <li className="px-1 ">
              <Button
                classes={`flex max-w-[59px] items-center justify-center px-4 h-10 text-gray-500 bg-white border border-e-0 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                onClick={() => handlePageChangeClicked(currentPage - 1)}
                disabled={currentPage === 1}
                variant={currentPage === 1 ? 'disabled' : 'primary'}
              >
                {'<<'}
              </Button>
            </li>
            {/* First Page Button */}
            <li className="px-1 ">
              <Button
                classes={`flex max-w-[59px] items-center justify-center px-4 h-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                onClick={() => handlePageChangeClicked(1)}
                disabled={currentPage === 1}
                variant={currentPage === 1 ? 'disabled' : 'primary'}
              >
                First
              </Button>
            </li>
            {/* Page numbers */}
            {Array.from({ length: 3 }, (_, i) => {
              const pageNumber = startPage + i;
              return (
                pageNumber <= totalPages && (
                  <li key={pageNumber} className="px-1 hidden md:block ">
                    <Button
                      classes={`flex max-w-[59px] items-center justify-center px-4 h-10 ${currentPage === pageNumber
                        ? 'text-white bg-blue-500'
                        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                        } border border-gray-300 dark:border-gray-700`}
                      onClick={() => handlePageChangeClicked(pageNumber)}
                    >
                      {pageNumber}
                    </Button>
                  </li>
                )
              );
            })}
            <li className='block px-3 flex-row items-center justify-center w-full text-white  md:hidden'>
              {currentPage} of {totalPages}
            </li>

            {/* Last Page Button */}
            <li className="px-1  ">
              <Button
                classes={`flex max-w-[59px] items-center justify-center px-4 h-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                onClick={() => handlePageChangeClicked(totalPages)}
                disabled={currentPage === totalPages}
                variant={currentPage === totalPages ? 'disabled' : 'primary'}
              >
                Last
              </Button>
            </li>
            {/* Next Button */}
            <li className="px-1  ]">
              <Button
                classes={`flex max-w-[59px] items-center justify-center px-4 h-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                onClick={() => handlePageChangeClicked(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
                variant={currentPage === totalPages || totalPages === 0 ? 'disabled' : 'primary'}
              >
                {'>>'}
              </Button>
            </li>

          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;

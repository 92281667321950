import React from 'react';
import Button from '../Button';
import { FaInfinity, FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ManageLicensesButtons = ({ handleClick }) => {


  const { t } = useTranslation('translation');
  return (
    <>
      <div className="w-full mb-4">
        <Button type="button" variant="primary" onClick={() => handleClick('new')} >    <span className='flex justify-center items-center' >   <FaPlus className="mr-2" /> {t('buy_new_license')}  </span> </Button>
      </div>
      <div className="w-full mb-4">
        <Button type="button" variant="primary" onClick={() => handleClick('extend')} >    <span className='flex justify-center items-center' >  <FaInfinity className="mr-2" /> {t('extend_or_renew')}  </span> </Button>
      </div>
    </>
  );
};

export default ManageLicensesButtons;

import React from 'react';
import './Loader.css'; // Import or define your loader styles here

const Loader = ({ isLoading }) => {

  return (
    <>
      {isLoading &&
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </div>}
    </>

  );
};

export default Loader;

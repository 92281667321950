import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import useDisableOverflow from '../hooks/useDisableOverflow';
import { useTranslation } from 'react-i18next';

// Make sure to set the app root element for accessibility
Modal.setAppElement('#root');

const NewOrderModal = ({ isOpen, onClose, userId, licenseType }) => {
  const [licenseId, setLicenseId] = useState(''); // State for hardware ID
  const navigate = useNavigate(); // Hook for navigation
  const { t } = useTranslation('translation');
  useDisableOverflow(isOpen);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (licenseId.trim()) {
      // Navigate to the /payment route, passing hardwareId as licenseId
      navigate(`/evaluate-order`, { state: { userId, licenseId: licenseId.trim(), type: licenseType } });
    }
  };


  const handleModalClose = () => {
    onClose();
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="New Order Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground overflow-hidden rounded-lg text-left outline-none sm:max-w-lg sm:w-full  w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto "     >
      <div className="p-6 relative">
        <h1 className="text-3xl text-lightMainText dark:gradient-text font-bold capitalize"> {licenseType === 'new' ? t('new') : t('extend')}  {t('license')} </h1>
        {/* Hardware ID input form */}
        <form onSubmit={handleSubmit} className="flex flex-col items-center mt-4">
          <input
            type="text"
            required
            placeholder={t('enter_license_id')}
            value={licenseId}
            onChange={(e) => setLicenseId(e.target.value)}
            className="  mb-4 text-white text-lg border-blue-500"
          />
          <div className="flex justify-end">
            <Button type="submit" variant='primary' >{t('confirm')}</Button>
          </div>
        </form>
      </div>
    </Modal >
  );
};

export default NewOrderModal;

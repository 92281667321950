import React from 'react';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';

const ConfirmationDialog = ({ message, closeDialog }) => {


  const handleCloseDialog = () => {
    closeDialog();
  }

  useDisableOverflow(message !== '');


  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]"
      onClick={handleCloseDialog}
    >
      <div className="bg-white dark:bg-mainBackground rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 border-2 border-blue-500">
        <p className="text-lightMainText dark:text-headingText text-lg font-semibold text-center mb-4">{message}</p>
        <div className="flex justify-center space-x-4">
          <Button
            variant='primary'
            onClick={handleCloseDialog}
          >
            OK
          </Button>

        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;

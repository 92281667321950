import { FaPlay, FaStop, /* FaSyncAlt, */ FaTimes } from "react-icons/fa";

const FilterButtons = ({ liveMode, onHandleLiveMode,/*  onHandleFetchLogs, */ onHandleReset }) => {
  const buttonClasses = 'h-[50px] flex items-center justify-center appearance-none border-2 rounded-md px-4 py-2 outline-none cursor-pointer w-full bg-white dark:bg-mainBackground text-lg text-lightCardText dark:text-white';
  const borderColor = liveMode ? 'border-red-500 text-red-500' : 'border-blue-500';

  return (
    <>
      <div className="flex flex-col mb-2 w-full sm:w-auto  justify-end">
        <div className={`${buttonClasses} ${borderColor}`} onClick={onHandleLiveMode}>
          {liveMode ? <FaStop className="mr-2" /> : <FaPlay className="mr-2 " />}  {liveMode ? 'Stop' : 'Live'}
        </div>
      </div>
      {/* Refresh Button */}
      {/*    <div className="flex flex-col mb-2 w-full sm:w-auto  justify-end">
        <div className={`${buttonClasses} border-blue-500`} onClick={onHandleFetchLogs}>
          <FaSyncAlt className="mr-2" />
          Refresh
        </div>
      </div> */}

      {/* Reset Button */}
      <div className="flex flex-col mb-2 w-full sm:w-auto  justify-end">
        <div className={`${buttonClasses} border-blue-500`} onClick={onHandleReset}>
          <FaTimes className="mr-2" />
          Reset
        </div>
      </div>
    </>
  );
};

export default FilterButtons;



import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

const Searchbar = ({ searchTerm, setSearchTerm }) => {

  const { t } = useTranslation('translation');
  return (<div className="flex flex-col md:flex-row justify-between items-center mb-4 m-auto max-w-3xl">
    <div className="w-full mb-2">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={t('search_placeholder')}
          className="w-full px-4 py-2 border-2 rounded-xl text-lg text-white border-blue-500"
        />
        <FaSearch className="absolute right-4 top-4 text-white text-lg" />
      </div>
    </div>
  </div>);
};

export default Searchbar;
import React from 'react';
import { useTranslation } from 'react-i18next';
import PriceItem from './PriceItem';

const Prices = () => {

  const { t } = useTranslation('prices');
  const titleClasses = 'font-bold text-lightCardText dark:text-headingText text-2xl';
  const contentClasses = 'text-lightCardText dark:text-white text-xl leading-10 mt-0 mb-1';
  const feeClasses = 'text-lightCardText dark:text-white text-xl leading-0 mt-0 mb-1';

  return (
    <div className="relative">
      <section className="flex flex-col md:w-1/2 lg:w-1/2 w-full m-auto bg-white dark:bg-mainBackground">
        <div className="flex-grow flex items-center justify-center flex-col">
          <h1 className="text-2xl text-center  text-lightMainText dark:text-white  mt-0 md:mt-8 mb-6 font-bold">
            {t('prices_heading')}
          </h1>


          <div className="faq-content dark:text-white">

            {[...Array(7)].map((_, i) => (
              <PriceItem key={i} classes={{ titleClasses, contentClasses, feeClasses }} text={{ title: t(`prices_${i + 1}_title`), content: t(`prices_${i + 1}_content`), feeParagraph: `${t('estimated_paypal_fees')} ${t(`prices_${i + 1}_fee`)}` }} />
            ))}
            <p className={feeClasses}> {t('please_note')}</p>
          </div>

        </div>
      </section>
    </div>
  );
}

export default Prices;

import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import Button from '../Button';
import useDisableOverflow from '../../hooks/useDisableOverflow';
import InputPassword from '../Input/InputPassword.js';
import { useTranslation } from 'react-i18next';
import { REGEX_TYPE } from '../../Constants.ts';

// Make sure to set the app root element for accessibility
Modal.setAppElement('#root');

const ChangePasswordModal = ({ isOpen, onClose, formData, setFormData, handleUpdatePassword }) => {
  const { t } = useTranslation('translation');
  const [passwordControl, setPasswordControl] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState(false);

  useDisableOverflow(isOpen);

  useEffect(() => {
    setFormData({ ...formData, password: '' });

  }, []);


  const handleModalClose = useCallback(() => onClose(), [onClose]);

  const validatePassword = (password) => {
    const isValid = REGEX_TYPE.PASSWORD.test(password);
    setPasswordValidationError(!isValid);
    return isValid;
  };

  const handlePasswordChange = useCallback((e) => {
    console.log(e.target.value);
    const password = e.target.value;
    setFormData((prev) => ({ ...prev, password }));
    if (password !== '') {
      validatePassword(password);
    }
  }, [setFormData]);

  const handleCheckPasswordControl = useCallback((e) => {
    setPasswordControl(e.target.value);
    setPasswordError(false);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (passwordControl === formData.password) {
      handleUpdatePassword();
    } else {
      setPasswordError(true);
    }
  }, [handleUpdatePassword, passwordControl, formData.password]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="User Form Modal"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      className="bg-white dark:bg-mainBackground overflow-hidden rounded-lg text-left outline-none sm:max-w-lg sm:w-full w-[95%] md:w-1/2 lg:w-1/3 max-h-[99vh] overflow-y-auto"
    >
      <div className="p-6 relative">
        <h1 className=" text-lightMainText dark:gradient-text  text-3xl font-bold">{t('change_password')}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <span className="absolute top-0 right-0 m-4 text-gray-500 hover:text-white focus:outline-none cursor-pointer" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </span>

          <InputPassword
            top='[40px]'
            handleSetValue={handlePasswordChange}
            value={formData.password || ''}
            id='pass'
            inputClasses={`border-blue-500 ${passwordValidationError ? 'border-red-500' : ''}`}
            error={passwordValidationError}
            helperText={passwordValidationError ? t('password_invalid') : ''}
          />
          <InputPassword
            top='[40px]'
            handleSetValue={handleCheckPasswordControl}
            value={passwordControl}
            id='control'
            inputClasses={`border-blue-500 ${passwordError ? 'border-red-500' : ''}`}
            label='Control Password'
            error={passwordError}
            helperText={passwordError ? t('pass_dont_match') : ''}
          />

          <div className="flex justify-center space-x-4">
            <Button variant="secondary" type="button" onClick={onClose} className="mr-2">{t('cancel')}</Button>
            <Button variant="primary" type="submit">{t('confirm')}</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SelectCustom = ({
  required = true,
  name,
  value,
  handleSetValue,
  inputClasses,
  placeholder,
  data,
  index,
}) => {

  return (
    <div className="relative text-lightCardText dark:text-white" key={index}>
      <select
        id={name}
        required={required}
        value={value}
        onChange={handleSetValue}
        className={`text-lightCardText dark:text-white relative ${inputClasses}`}
      >
        <option value="">{placeholder}</option>
        {data?.map((item) => (
          <option
            key={item.code + item.id}
            value={name === 'taxType' ? `${item.code} ${item?.country}` : `${item.code}`}
          >
            {item.label} {name === 'taxType' && <>- ({item?.country})</>}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faChevronDown} className="absolute top-4 right-4 text-xl pointer-events-none" />
    </div>
  );
};

export default SelectCustom;

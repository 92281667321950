// src/components/Button.js
import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, onClick, disabled, variant = 'primary', classes }) => {

  const baseClasses = "text-center font-bold normal-case transition-colors duration-200 ease-out py-4 px-8 focus:outline-none focus:shadow-outline rounded-xl gap-1 text-xl   w-full no-underline " + classes;
  const variantClasses = {
    primary: "border-0 dark:border-2  dark:border-pink-500 text-lightMainText dark:text-secondaryText hover:text-secondaryText bg-lightPrimaryButton dark:bg-pink-500 hover:bg-pink-400 hover:border-pink-400 ",
    secondary: " border-2 border-pink-300 dark:border-pink-500 text-lightMainText dark:text-pink-500 dark:hover:text-secondaryText bg-transparent hover:bg-pink-300  dark:hover:bg-pink-500   ",
    disabled: "bg-gray-200 hover:bg-gray-200 border border-gray-300 text-slate-950 cursor-not-allowed",
    success: "bg-green-500 hover:bg-green-700",
    danger: "text-white bg-red-500 hover:bg-red-700",
    paypal: "border-2 border-blue-500 text-white hover:text-secondaryText bg-blue-500 hover:bg-blue-400 hover:border-blue-400 ",
    stripe: "border-2 border-blue-500 dark:border-white text-[#5167FC] hover:text-secondaryText bg-white hover:bg-blue-400 hover:border-blue-400 ",
  };

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant] || variantClasses.primary}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'disabled', 'paypal', 'stripe']),
};

export default Button;

// api.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// Create an axios instance
const api = axios.create({
  baseURL: apiUrl
});

// Add a request interceptor to include the JWT token in the Authorization header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Or get the token from another source
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const validateTokenUtil = async () => {
  const response = await api.get(`${apiUrl}/api/auth/validate-token`);
  return response.data;
};

export const fetchUsers = async (page, limit = 10, searchTerm) => {
  const response = await api.get(`${apiUrl}/api/users`, {
    params: { page, limit, searchTerm }
  });
  return response.data;
};

export const fetchAllUsers = async () => {
  const response = await api.get(`${apiUrl}/api/users/getAll`)
  const data = response.data.users.filter(user => user.attendance);
  return data;
};

export const getAllLogs = async (dateFrom, dateTo, email, timeFrom, timeTo, logLevel, licenseId) => {
  const response = await api.get(`${apiUrl}/api/logs/get-logs`, {
    params: { dateFrom, dateTo, email, timeFrom, timeTo, logLevel, licenseId }
  });
  return response.data;
};


export const getLastInvoiceId = async () => {
  const response = await api.get(`${apiUrl}/api/invoices/last-invoice-id`)
  return response.data;
};
export const setNextInvoiceId = async () => {
  const response = await api.post(`${apiUrl}/api/invoices/set-invoice-id`,)
  return response.data;
};

export const createUsers = async (user) => {
  const response = await api.post(`${apiUrl}/api/users`, user);
  return response.data;
};
export const savePaymentCheckout = async (paymentData) => {
  const response = await api.post(`${apiUrl}/api/stripe/save-payment-checkout`, { paymentData });
  return response.data;
};

export const userLogin = async (email, password) => {
  const response = await axios.post(`${apiUrl}/api/auth/login`, { email, password });
  return response.data;
};
export const requestNewPassword = async (email) => {
  const response = await api.post(`${apiUrl}/api/users/request-reset-password`, { email });
  return response.data;
};
export const resetPassword = async (token, password) => {
  const response = await api.post(`${apiUrl}/api/users/reset-password/${token}`, { password });
  return response.data;
};

export const createStripeIntent = async (amount, currency, userId, licenseId) => {
  const response = await api.post(`${apiUrl}/api/stripe/create-payment-intent`, { amount, currency, userId, licenseId });
  return response.data;
};
export const createPayPalOrder = async (amount, currency, user, licenseId) => {
  const response = await api.post(`${apiUrl}/api/paypal/create-order`, { amount, currency, user, licenseId });
  return response.data;
};
export const createStripeCheckoutSession = async (items, type, selectedLicense) => {
  const response = await api.post(`${apiUrl}/api/stripe/create-checkout-session`, { items, type, selectedLicense });
  return response.data;
};
export const captureStripePayment = async (paymentIntentId, userId, licenseId, invoiceFields, type, selectedLicense) => {
  const response = await api.post(`${apiUrl}/api/stripe/capture-payment`, { paymentIntentId, userId, licenseId, invoiceFields, type, selectedLicense });
  return response.data;
};

export const getUserById = async (id) => {
  const response = await api.get(`${apiUrl}/api/users/get-user/${id}`);
  return response.data;
};
export const updateSelectedUser = async (id, user) => {
  const response = await api.put(`${apiUrl}/api/users/${id}`, user);
  return response.data;
};
export const updateUserPassword = async (id, password) => {

  const response = await api.put(`${apiUrl}/api/users/update-password/${id}`, { password });
  return response.data;
};
export const updateUserPasswordAdmin = async (id, password) => {

  const response = await api.put(`${apiUrl}/api/users/update-password-admin/${id}`, { password });
  return response.data;
};

export const deleteSelectedLicenseFromUser = async (userId, licenseToBeDeleted) => {
  const response = await api.post(`${apiUrl}/api/users/delete-selected-license`, { userId, licenseToBeDeleted });
  return response.data;
};
export const addNewLicenseToUser = async (userId, licenseToBeAdded) => {
  const response = await api.post(`${apiUrl}/api/users/add-license-to-user`, { userId, licenseToBeAdded });
  return response.data;
};


export const deleteSelectedUser = async (id, user) => {
  const response = await api.delete(`${apiUrl}/api/users/${id}`, user);
  return response.data;
};
export const deleteLogs = async (days = 30) => {
  const response = await api.delete(`${apiUrl}/api/logs/delete-old-logs/${days}`);
  return response.data;
};
export const capturePayPalOrder = async (orderId, userId, licenseId, invoiceFields, type, selectedLicense) => {
  if (!orderId || !userId || !licenseId || !invoiceFields) {
    throw new Error('Missing required parameters');
  }
  const response = await api.post(`${apiUrl}/api/paypal/capture-order`, { orderId, userId, licenseId, invoiceFields, type, selectedLicense });
  return response.data;
};
export const requestActivationCode = async (orderId, userId, licenseId) => {
  if (!orderId || !userId || !licenseId) {
    throw new Error('Missing required parameters');
  }
  const response = await api.post(`${apiUrl}/api/users/request-activation-code`, { orderId, userId, licenseId });
  return response.data;
};
export const updateLicenseFromLicenses = async (updatedLicense, originalLicenseId, userId, originalOrderId) => {
  if (!updatedLicense || !userId) {
    throw new Error('Missing required parameters');
  }
  const response = await api.put(`${apiUrl}/api/paypal/update-license`, { updatedLicense, originalLicenseId, userId, originalOrderId });
  return response.data;
};


export const fetchLicensesByUserId = async (page = 1, limit = 12, userId) => {
  const response = await api.get(`${apiUrl}/api/users/orders-by-userid?page=${page}&limit=${limit}&userId=${userId}`);
  return response;
};
